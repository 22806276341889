import { UNKNOWN } from './textConsts';

const ages = [
  { value: 0, label: '0 month' },
  { value: 1, label: '1 month' },
  { value: 2, label: '2 months' },
  { value: 3, label: '3 months' },
  { value: 4, label: '4 months' },
  { value: 5, label: '5 months' },
  { value: 6, label: '6 months' },
  { value: 7, label: '7 months' },
  { value: 8, label: '8 months' },
  { value: 9, label: '9 months' },
  { value: 10, label: '10 months' },
  { value: 11, label: '11 months' },
  { value: 12, label: '12 months' },
  { value: 13, label: '13 months' },
  { value: 14, label: '14 months' },
  { value: 15, label: '15 months' },
  { value: 16, label: '16 months' },
  { value: 17, label: '17 months' },
  { value: 18, label: '18 months' },
  { value: 19, label: '19 months' },
  { value: 20, label: '20 months' },
  { value: 21, label: '21 months' },
  { value: 22, label: '22 months' },
  { value: 23, label: '23 months' },
  { value: 24, label: '24 months' },
  { value: 25, label: '25 months' },
  { value: 26, label: '26 months' },
  { value: 27, label: '27 months' },
  { value: 28, label: '28 months' },
  { value: 29, label: '29 months' },
  { value: 30, label: '30 months' },
  { value: 31, label: '31 months' },
  { value: 32, label: '32 months' },
  { value: 33, label: '33 months' },
  { value: 34, label: '34 months' },
  { value: 35, label: '35 months' },
  { value: 36, label: '3 years' },
  { value: 48, label: '4 years' },
  { value: 60, label: '5 years' },
  { value: 72, label: '6 years' },
  { value: 84, label: '7 years' },
  { value: 96, label: '8 years' },
  { value: 108, label: '9 years' },
  { value: 120, label: '10 years' },
  { value: 132, label: '11 years' },
  { value: 144, label: '12 years' },
  { value: 156, label: '13 years' },
  { value: 168, label: '14 years' },
  { value: 180, label: '15 years' },
  { value: 192, label: '16 years' }
];

export default ages;

export function getAges(agesArray) {
  const list = [];

  for (const age of agesArray) {
    const ageObject = ages.find((item) => item.value === age);

    if (ageObject) {
      list.push(ageObject);
    } else {
      list.push({ value: age, label: UNKNOWN });
    }
  }

  return list;
}
