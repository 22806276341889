import moment from 'moment';
import api from '../api';
import { CREATED, NO_CONTENT, OK } from '../config/httpStatuses';
import { ERROR_ON_THE_SERVER } from '../config/textConsts';
import PaginationPageConverter from '../classes/PaginationPageConverter';
import ErrorMessage from '../classes/ErrorMessage';

const GET_APP_QUOTES_LIST_PROGRESS = 'GET_APP_QUOTES_LIST_PROGRESS';
const GET_APP_QUOTES_LIST_SUCCESS = 'GET_APP_QUOTES_LIST_SUCCESS';
const GET_APP_QUOTES_LIST_FAILED = 'GET_APP_QUOTES_LIST_FAILED';
const APP_QUOTES_FORM_SUBMIT_PROGRESS = 'APP_QUOTES_FORM_SUBMIT_PROGRESS';
const APP_QUOTES_FORM_SUBMIT_SUCCESS = 'APP_QUOTES_FORM_SUBMIT_SUCCESS';
const APP_QUOTES_FORM_SUBMIT_FAILED = 'APP_QUOTES_FORM_SUBMIT_FAILED';
const GET_APP_QUOTE_PROGRESS = 'GET_APP_QUOTE_PROGRESS';
const GET_APP_QUOTE_SUCCESS = 'GET_APP_QUOTE_SUCCESS';
const GET_ALL_QUOTE_FAILED = 'GET_ALL_QUOTE_FAILED';
const APP_QUOTES_CLEAR_STATE = 'APP_QUOTES_CLEAR_STATE';

const actionGetAppQuotesListProgress = () => ({
  type: GET_APP_QUOTES_LIST_PROGRESS
});

const actionGetAppQuotesListSuccess = (payload) => ({
  type: GET_APP_QUOTES_LIST_SUCCESS,
  payload
});

const actionGetAppQuotesListFailed = () => ({
  type: GET_APP_QUOTES_LIST_FAILED
});

const actionAppQuotesFormSubmitProgress = () => ({
  type: APP_QUOTES_FORM_SUBMIT_PROGRESS
});

const actionAppQuotesFormSubmitSuccess = (payload) => ({
  type: APP_QUOTES_FORM_SUBMIT_SUCCESS,
  payload
});

const actionAppQuotesFormSubmitFailed = (payload) => ({
  type: APP_QUOTES_FORM_SUBMIT_FAILED,
  payload
});

const actionGetAppQuoteProgress = () => ({
  type: GET_APP_QUOTE_PROGRESS
});

const actionGetAppQuoteSuccess = (payload) => ({
  type: GET_APP_QUOTE_SUCCESS,
  payload
});

const actionGetAppQuoteFailed = () => ({
  type: GET_ALL_QUOTE_FAILED
});

const actionAppQuotesClearState = () => ({
  type: APP_QUOTES_CLEAR_STATE
});

const convertListToTable = (data) =>
  new PaginationPageConverter(data, (doc) => ({
    id: doc.id,
    title: doc.title,
    createdAt: moment(doc.createdAt).format('D MMM YYYY, HH:mm')
  })).getConvertedData();

const getQuotesList = (params) => (dispatch) => {
  dispatch(actionGetAppQuotesListProgress());

  api
    .get('/app/quote', { params })
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetAppQuotesListSuccess(convertListToTable(data)));
    })
    .catch(() => dispatch(actionGetAppQuotesListFailed()));
};

const convertSetQuoteToDB = ({ title, content }) => ({
  title,
  content: content || ''
});

const createQuote = (formData) => (dispatch) => {
  const dto = convertSetQuoteToDB(formData);
  dispatch(actionAppQuotesFormSubmitProgress());

  api
    .post('/app/quote/create', dto)
    .then(({ status }) => {
      if (status !== CREATED) {
        throw new Error();
      }

      dispatch(actionAppQuotesFormSubmitSuccess('The new quote has been successfully created'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionAppQuotesFormSubmitFailed(msg));
    });
};

const convertDBQuoteToEdit = ({ title, content, created, updated, createdAt, updatedAt }) => ({
  title,
  content,
  created,
  updated,
  createdAt,
  updatedAt
});

const getQuote = (quoteId) => (dispatch) => {
  dispatch(actionGetAppQuoteProgress());

  api
    .get(`/app/quote/${quoteId}`)
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetAppQuoteSuccess(convertDBQuoteToEdit(data)));
    })
    .catch(() => dispatch(actionGetAppQuoteFailed()));
};

const updateQuote = (quoteId, formData) => (dispatch) => {
  const dto = convertSetQuoteToDB(formData);
  dispatch(actionAppQuotesFormSubmitProgress());

  api
    .put(`/app/quote/${quoteId}`, dto)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionAppQuotesFormSubmitSuccess('The quote has been successfully edited'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionAppQuotesFormSubmitFailed(msg));
    });
};

const removeQuote = (quoteId) => (dispatch) => {
  dispatch(actionAppQuotesFormSubmitProgress());

  api
    .delete(`/app/quote/${quoteId}`)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionAppQuotesFormSubmitSuccess('The quote has been successfully removed'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionAppQuotesFormSubmitFailed(msg));
    });
};

export {
  GET_APP_QUOTES_LIST_PROGRESS,
  GET_APP_QUOTES_LIST_SUCCESS,
  GET_APP_QUOTES_LIST_FAILED,
  APP_QUOTES_FORM_SUBMIT_PROGRESS,
  APP_QUOTES_FORM_SUBMIT_SUCCESS,
  APP_QUOTES_FORM_SUBMIT_FAILED,
  GET_APP_QUOTE_PROGRESS,
  GET_APP_QUOTE_SUCCESS,
  GET_ALL_QUOTE_FAILED,
  APP_QUOTES_CLEAR_STATE,
  actionAppQuotesClearState,
  getQuotesList,
  createQuote,
  getQuote,
  updateQuote,
  removeQuote
};
