import moment from 'moment';
import api from '../api';
import { CREATED, NO_CONTENT, OK } from '../config/httpStatuses';
import { ERROR_ON_THE_SERVER } from '../config/textConsts';
import PaginationPageConverter from '../classes/PaginationPageConverter';
import User from '../classes/User';
import ErrorMessage from '../classes/ErrorMessage';

const GET_APP_SPEAKERS_LIST_PROGRESS = 'GET_APP_SPEAKERS_LIST_PROGRESS';
const GET_APP_SPEAKERS_LIST_SUCCESS = 'GET_APP_SPEAKERS_LIST_SUCCESS';
const GET_APP_SPEAKERS_LIST_FAILED = 'GET_APP_SPEAKERS_LIST_FAILED';
const APP_SPEAKERS_FORM_SUBMIT_PROGRESS = 'APP_SPEAKERS_FORM_SUBMIT_PROGRESS';
const APP_SPEAKERS_FORM_SUBMIT_SUCCESS = 'APP_SPEAKERS_FORM_SUBMIT_SUCCESS';
const APP_SPEAKERS_FORM_SUBMIT_FAILED = 'APP_SPEAKERS_FORM_SUBMIT_FAILED';
const GET_APP_SPEAKER_PROGRESS = 'GET_APP_SPEAKER_PROGRESS';
const GET_APP_SPEAKER_SUCCESS = 'GET_APP_SPEAKER_SUCCESS';
const GET_APP_SPEAKER_FAILED = 'GET_APP_SPEAKER_FAILED';
const APP_SPEAKERS_CLEAR_STATE = 'APP_SPEAKERS_CLEAR_STATE';

const actionGetSpeakersListProgress = () => ({
  type: GET_APP_SPEAKERS_LIST_PROGRESS
});

const actionGetSpeakersListSuccess = (payload) => ({
  type: GET_APP_SPEAKERS_LIST_SUCCESS,
  payload
});

const actionGetSpeakersListFailed = () => ({
  type: GET_APP_SPEAKERS_LIST_FAILED
});

const actionSpeakersFormSubmitProgress = () => ({
  type: APP_SPEAKERS_FORM_SUBMIT_PROGRESS
});

const actionSpeakersFormSubmitSuccess = (payload) => ({
  type: APP_SPEAKERS_FORM_SUBMIT_SUCCESS,
  payload
});

const actionSpeakersFormSubmitFailed = (payload) => ({
  type: APP_SPEAKERS_FORM_SUBMIT_FAILED,
  payload
});

const actionGetSpeakerProgress = () => ({
  type: GET_APP_SPEAKER_PROGRESS
});

const actionGetSpeakerSuccess = (payload) => ({
  type: GET_APP_SPEAKER_SUCCESS,
  payload
});

const actionGetSpeakerFailed = () => ({
  type: GET_APP_SPEAKER_FAILED
});

const actionSpeakersClearState = () => ({
  type: APP_SPEAKERS_CLEAR_STATE
});

const convertListToTable = (data) =>
  new PaginationPageConverter(data, (doc) => ({
    id: doc.id,
    name: `${doc.user.firstname} ${doc.user.surname}`,
    courses: doc.countCourses,
    createdAt: moment(doc.createdAt).format('D MMM YYYY, HH:mm')
  })).getConvertedData();

const getSpeakersList = (params) => (dispatch) => {
  dispatch(actionGetSpeakersListProgress());

  api
    .get('/app/speaker', { params })
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetSpeakersListSuccess(convertListToTable(data)));
    })
    .catch(() => dispatch(actionGetSpeakersListFailed()));
};

const convertSetSpeakerToDB = ({ user, instagram, facebook, linkedin, website, occupation, content }) => {
  const instagramUrl = instagram || undefined;
  const facebookUrl = facebook || undefined;
  const linkedinUrl = linkedin || undefined;
  const websiteUrl = website || undefined;
  const contacts =
    instagramUrl || facebookUrl || linkedinUrl || websiteUrl
      ? {
          instagram: instagramUrl,
          facebook: facebookUrl,
          linkedin: linkedinUrl,
          website: websiteUrl
        }
      : undefined;

  return {
    user: typeof user === 'string' ? user : undefined,
    occupation,
    content: content || '',
    contacts
  };
};

const createSpeaker = (formData) => (dispatch) => {
  const dto = convertSetSpeakerToDB(formData);
  dispatch(actionSpeakersFormSubmitProgress());

  api
    .post('/app/speaker/create', dto)
    .then(({ status }) => {
      if (status !== CREATED) {
        throw new Error();
      }

      dispatch(actionSpeakersFormSubmitSuccess('The new speaker has been successfully created'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionSpeakersFormSubmitFailed(msg));
    });
};

const convertDBSpeakerToEdit = ({ user, occupation, content, contacts, created, updated, createdAt, updatedAt }) => {
  const instagram = contacts && typeof contacts.instagram === 'string' ? contacts.instagram : '';
  const facebook = contacts && typeof contacts.facebook === 'string' ? contacts.facebook : '';
  const linkedin = contacts && typeof contacts.linkedin === 'string' ? contacts.linkedin : '';
  const website = contacts && typeof contacts.website === 'string' ? contacts.website : '';

  return {
    user,
    instagram,
    facebook,
    linkedin,
    website,
    occupation,
    content,
    created,
    updated,
    createdAt,
    updatedAt
  };
};

const getSpeaker = (speakerId) => (dispatch) => {
  dispatch(actionGetSpeakerProgress());

  api
    .get(`/app/speaker/${speakerId}`)
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetSpeakerSuccess(convertDBSpeakerToEdit(data)));
    })
    .catch(() => dispatch(actionGetSpeakerFailed()));
};

const updateSpeaker = (speakerId, formData) => (dispatch) => {
  const dto = convertSetSpeakerToDB(formData);
  dispatch(actionSpeakersFormSubmitProgress());

  api
    .put(`/app/speaker/${speakerId}`, dto)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionSpeakersFormSubmitSuccess('The speaker has been successfully edited'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionSpeakersFormSubmitFailed(msg));
    });
};

const removeSpeaker = (speakerId) => (dispatch) => {
  dispatch(actionSpeakersFormSubmitProgress());

  api
    .delete(`/app/speaker/${speakerId}`)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionSpeakersFormSubmitSuccess('The speaker has been successfully removed'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionSpeakersFormSubmitFailed(msg));
    });
};

const findUserByEmail = (filter) =>
  new Promise((resolve) => {
    api
      .get('/user/findByEmail', { params: { filter } })
      .then(({ status, data }) => {
        if (status !== 200) {
          throw new Error();
        }

        const users = data.map((user) => {
          const newUser = new User(user);

          return {
            user: newUser,
            value: `${newUser.name}${newUser.email ? ` (${newUser.email})` : ''}`
          };
        });

        resolve(users);
      })
      .catch(() => resolve([]));
  });

const checkSpeakerIsExists = (user) =>
  new Promise((resolve, reject) => {
    api
      .get('/app/speaker/findByUser', { params: { user } })
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve();
      })
      .catch(() => reject());
  });

export {
  GET_APP_SPEAKERS_LIST_PROGRESS,
  GET_APP_SPEAKERS_LIST_SUCCESS,
  GET_APP_SPEAKERS_LIST_FAILED,
  APP_SPEAKERS_FORM_SUBMIT_PROGRESS,
  APP_SPEAKERS_FORM_SUBMIT_SUCCESS,
  APP_SPEAKERS_FORM_SUBMIT_FAILED,
  GET_APP_SPEAKER_PROGRESS,
  GET_APP_SPEAKER_SUCCESS,
  GET_APP_SPEAKER_FAILED,
  APP_SPEAKERS_CLEAR_STATE,
  actionSpeakersClearState,
  getSpeakersList,
  createSpeaker,
  getSpeaker,
  updateSpeaker,
  removeSpeaker,
  findUserByEmail,
  checkSpeakerIsExists
};
