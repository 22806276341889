import store from './config/configureStore';

const TokensService = (() => {
  let service;

  function getServiceFn() {
    if (!service) {
      service = this;
      return service;
    }

    return service;
  }

  function getAccessTokenFn() {
    const { tokens } = store.getState().auth;

    if (tokens) {
      const { access_token: accessToken } = tokens;
      return accessToken;
    }

    return null;
  }

  function getRefreshTokenFn() {
    const { tokens } = store.getState().auth;

    if (tokens) {
      const { refresh_token: refreshToken } = tokens;
      return refreshToken;
    }

    return null;
  }

  return {
    getService: getServiceFn,
    getAccessToken: getAccessTokenFn,
    getRefreshToken: getRefreshTokenFn
  };
})();

export default TokensService;
