import ThemeOptions from './ThemeOptions';
import appArticles from './AppArticlesReducer';
import appCourses from './AppCoursesReducer';
import appPages from './AppPagesReducer';
import appPastime from './AppPastimeReducer';
import appPastimeCategories from './AppPastimeCategoriesReducer';
import appSpeakers from './AppSpeakersReducer';
import appQuotes from './AppQuotesReducer';
import articles from './ArticlesReducer';
import auth from './AuthReducer';
import authors from './AuthorsReducer';
import categories from './CategoriesReducer';
import ig from './ImageGalleryReducer';
import pages from './PagesReducer';
import tags from './TagsReducer';
import users from './UsersReducer';
import settings from './SettingsReducer';

export default {
  ThemeOptions,
  appArticles,
  appCourses,
  appPages,
  appPastime,
  appPastimeCategories,
  appSpeakers,
  appQuotes,
  articles,
  auth,
  authors,
  categories,
  ig,
  pages,
  tags,
  users,
  settings
};
