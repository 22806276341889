import update from 'immutability-helper';
import { PROGRESS, SUCCESS, FAILED } from '../config/types';
import {
  IG_GET_INITIAL_DATA_PROGRESS,
  IG_GET_INITIAL_DATA_SUCCESS,
  IG_GET_INITIAL_DATA_FAILED,
  IG_ADD_FOLDER_TO_LIST_PROGRESS,
  IG_ADD_FOLDER_TO_LIST_SUCCESS,
  IG_EDIT_ACTIVE_FOLDER_PROGRESS,
  IG_EDIT_ACTIVE_FOLDER_SUCCESS,
  IG_REMOVE_ACTIVE_FOLDER_PROGRESS,
  IG_REMOVE_ACTIVE_FOLDER_SUCCESS,
  IG_CHANGE_ACTIVE_FOLDER,
  IG_LOAD_IMAGES_PROGRESS,
  IG_LOAD_IMAGES_SUCCESS,
  IG_LOAD_IMAGES_FAILED,
  IG_SET_SEARCH_VALUE_PROGRESS,
  IG_SET_SEARCH_VALUE_SUCCESS,
  IG_OPEN_WINDOW,
  IG_IMAGE_UPLOAD_PROGRESS,
  IG_IMAGE_UPLOAD_SUCCESS,
  IG_BACK_TO_FOLDERS,
  IG_CHANGE_ACTIVE_IMAGE,
  IG_REMOVE_ACTIVE_IMAGE_PROGRESS,
  IG_REMOVE_ACTIVE_IMAGE_SUCCESS,
  IG_MOVE_ACTIVE_IMAGE_PROGRESS,
  IG_MOVE_ACTIVE_IMAGE_SUCCESS,
  IG_CHANGE_DESCRIPTION_ACTIVE_IMAGE,
  IG_CLEAR_STATE
} from '../actions/ImageGalleryAction';

const initialState = {
  window: 1,
  folderListStatus: '',
  imageListStatus: '',
  isLoad: false,
  search: '',
  activeFolderId: '',
  currentFolder: {},
  folders: [],
  images: [],
  page: 0,
  hasNextPage: false,
  activeImageId: '',
  currentImage: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case IG_GET_INITIAL_DATA_PROGRESS: {
      return update(state, {
        folderListStatus: { $set: PROGRESS }
      });
    }
    case IG_GET_INITIAL_DATA_SUCCESS: {
      return update(state, {
        folderListStatus: { $set: SUCCESS },
        isLoad: { $set: true },
        activeFolderId: { $set: action.payload.activeFolderId },
        folders: { $set: action.payload.folders },
        currentFolder: { $set: action.payload.currentFolder }
      });
    }
    case IG_GET_INITIAL_DATA_FAILED: {
      return update(state, {
        folderListStatus: { $set: FAILED }
      });
    }
    case IG_ADD_FOLDER_TO_LIST_PROGRESS: {
      return update(state, {
        folderListStatus: { $set: PROGRESS }
      });
    }
    case IG_ADD_FOLDER_TO_LIST_SUCCESS: {
      return update(state, {
        folders: { $set: action.payload },
        folderListStatus: { $set: SUCCESS }
      });
    }
    case IG_EDIT_ACTIVE_FOLDER_PROGRESS: {
      return update(state, {
        folderListStatus: { $set: PROGRESS }
      });
    }
    case IG_EDIT_ACTIVE_FOLDER_SUCCESS: {
      return update(state, {
        folders: { $set: action.payload.folders },
        currentFolder: { $set: action.payload.currentFolder },
        folderListStatus: { $set: SUCCESS }
      });
    }
    case IG_REMOVE_ACTIVE_FOLDER_PROGRESS: {
      return update(state, {
        folderListStatus: { $set: PROGRESS }
      });
    }
    case IG_REMOVE_ACTIVE_FOLDER_SUCCESS: {
      return update(state, {
        activeFolderId: { $set: action.payload.folderId },
        folders: { $set: action.payload.folders },
        currentFolder: { $set: action.payload.currentFolder },
        folderListStatus: { $set: SUCCESS },
        images: { $set: [] },
        page: { $set: 0 },
        hasNextPage: { $set: false }
      });
    }
    case IG_CHANGE_ACTIVE_FOLDER: {
      return update(state, {
        activeFolderId: { $set: action.payload.folderId },
        currentFolder: { $set: action.payload.currentFolder },
        search: { $set: '' },
        images: { $set: [] },
        page: { $set: 0 },
        hasNextPage: { $set: false },
        activeImageId: { $set: '' },
        currentImage: { $set: {} }
      });
    }
    case IG_LOAD_IMAGES_PROGRESS: {
      return update(state, {
        imageListStatus: { $set: PROGRESS }
      });
    }
    case IG_LOAD_IMAGES_SUCCESS: {
      return update(state, {
        imageListStatus: { $set: SUCCESS },
        images: { $set: action.payload.images },
        page: { $set: action.payload.page },
        hasNextPage: { $set: action.payload.hasNextPage }
      });
    }
    case IG_LOAD_IMAGES_FAILED: {
      return update(state, {
        imageListStatus: { $set: FAILED }
      });
    }
    case IG_SET_SEARCH_VALUE_PROGRESS: {
      return update(state, {
        activeFolderId: { $set: '' }
      });
    }
    case IG_SET_SEARCH_VALUE_SUCCESS: {
      return update(state, {
        activeFolderId: { $set: action.payload.folderId },
        currentFolder: { $set: action.payload.currentFolder },
        search: { $set: action.payload.search },
        images: { $set: [] },
        page: { $set: 0 },
        hasNextPage: { $set: false },
        activeImageId: { $set: '' },
        currentImage: { $set: {} }
      });
    }
    case IG_OPEN_WINDOW: {
      return update(state, {
        window: { $set: action.payload }
      });
    }
    case IG_IMAGE_UPLOAD_PROGRESS: {
      return update(state, {
        folderListStatus: { $set: PROGRESS },
        activeImageId: { $set: '' },
        currentImage: { $set: {} }
      });
    }
    case IG_IMAGE_UPLOAD_SUCCESS: {
      return update(state, {
        window: { $set: 1 },
        folderListStatus: { $set: SUCCESS },
        images: { $set: [] },
        page: { $set: 0 },
        hasNextPage: { $set: false }
      });
    }
    case IG_BACK_TO_FOLDERS: {
      return update(state, {
        window: { $set: 1 },
        images: { $set: [] },
        page: { $set: 0 },
        hasNextPage: { $set: false }
      });
    }
    case IG_CHANGE_ACTIVE_IMAGE: {
      return update(state, {
        activeImageId: { $set: action.payload.imageId },
        currentImage: { $set: action.payload.image }
      });
    }
    case IG_REMOVE_ACTIVE_IMAGE_PROGRESS: {
      return update(state, {
        folderListStatus: { $set: PROGRESS },
        imageListStatus: { $set: PROGRESS }
      });
    }
    case IG_REMOVE_ACTIVE_IMAGE_SUCCESS: {
      return update(state, {
        folderListStatus: { $set: SUCCESS },
        imageListStatus: { $set: SUCCESS },
        folders: { $set: action.payload.folders },
        images: { $set: action.payload.images },
        activeImageId: { $set: '' },
        currentImage: { $set: {} }
      });
    }
    case IG_MOVE_ACTIVE_IMAGE_PROGRESS: {
      return update(state, {
        folderListStatus: { $set: PROGRESS },
        imageListStatus: { $set: PROGRESS }
      });
    }
    case IG_MOVE_ACTIVE_IMAGE_SUCCESS: {
      return update(state, {
        folderListStatus: { $set: SUCCESS },
        imageListStatus: { $set: SUCCESS },
        folders: { $set: action.payload.folders },
        images: { $set: action.payload.images },
        activeImageId: { $set: '' },
        currentImage: { $set: {} }
      });
    }
    case IG_CHANGE_DESCRIPTION_ACTIVE_IMAGE: {
      return update(state, {
        images: { $set: action.payload.images },
        currentImage: { $set: action.payload.currentImage }
      });
    }
    case IG_CLEAR_STATE: {
      return update(state, {
        window: { $set: 1 },
        folderListStatus: { $set: '' },
        imageListStatus: { $set: '' },
        isLoad: { $set: false },
        search: { $set: '' },
        activeFolderId: { $set: '' },
        currentFolder: { $set: {} },
        folders: { $set: [] },
        images: { $set: [] },
        page: { $set: 0 },
        hasNextPage: { $set: false },
        activeImageId: { $set: '' },
        currentImage: { $set: {} }
      });
    }
    default:
      return state;
  }
}
