import moment from 'moment';
import api from '../api';
import { CREATED, NO_CONTENT, OK } from '../config/httpStatuses';
import { ERROR_ON_THE_SERVER } from '../config/textConsts';
import PaginationPageConverter from '../classes/PaginationPageConverter';
import ErrorMessage from '../classes/ErrorMessage';
import categoryDecors from '../config/categoryDecors';

const GET_CATEGORIES_LIST_PROGRESS = 'GET_CATEGORIES_LIST_PROGRESS';
const GET_CATEGORIES_LIST_SUCCESS = 'GET_CATEGORIES_LIST_SUCCESS';
const GET_CATEGORIES_LIST_FAILED = 'GET_CATEGORIES_LIST_FAILED';
const CATEGORIES_FORM_SUBMIT_PROGRESS = 'CATEGORIES_FORM_SUBMIT_PROGRESS';
const CATEGORIES_FORM_SUBMIT_SUCCESS = 'CATEGORIES_FORM_SUBMIT_SUCCESS';
const CATEGORIES_FORM_SUBMIT_FAILED = 'CATEGORIES_FORM_SUBMIT_FAILED';
const GET_CATEGORY_PROGRESS = 'GET_CATEGORY_PROGRESS';
const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
const GET_CATEGORY_FAILED = 'GET_CATEGORY_FAILED';
const CATEGORIES_CLEAR_STATE = 'CATEGORIES_CLEAR_STATE';

const actionGetCategoriesListProgress = () => ({
  type: GET_CATEGORIES_LIST_PROGRESS
});

const actionGetCategoriesListSuccess = (payload) => ({
  type: GET_CATEGORIES_LIST_SUCCESS,
  payload
});

const actionGetCategoriesListFailed = () => ({
  type: GET_CATEGORIES_LIST_FAILED
});

const actionCategoriesFormSubmitProgress = () => ({
  type: CATEGORIES_FORM_SUBMIT_PROGRESS
});

const actionCategoriesFormSubmitSuccess = (payload) => ({
  type: CATEGORIES_FORM_SUBMIT_SUCCESS,
  payload
});

const actionCategoriesFormSubmitFailed = (payload) => ({
  type: CATEGORIES_FORM_SUBMIT_FAILED,
  payload
});

const actionGetCategoryProgress = () => ({
  type: GET_CATEGORY_PROGRESS
});

const actionGetCategorySuccess = (payload) => ({
  type: GET_CATEGORY_SUCCESS,
  payload
});

const actionGetCategoryFailed = () => ({
  type: GET_CATEGORY_FAILED
});

const actionCategoriesClearState = () => ({
  type: CATEGORIES_CLEAR_STATE
});

const convertListToTable = (data) =>
  new PaginationPageConverter(data, (doc) => ({
    id: doc.id,
    title: doc.title,
    sorting: doc.sort,
    createdAt: moment(doc.createdAt).format('D MMM YYYY, HH:mm')
  })).getConvertedData();

const getCategoriesList = (params) => (dispatch) => {
  dispatch(actionGetCategoriesListProgress());

  api
    .get('/blog/category', { params })
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetCategoriesListSuccess(convertListToTable(data)));
    })
    .catch(() => dispatch(actionGetCategoriesListFailed()));
};

const convertSetCategoryToDB = ({ title, seoTitle, seoDescription, slug, decor, sort }) => ({
  title,
  seo: {
    title: seoTitle,
    description: seoDescription
  },
  slug,
  decor: decor.value,
  sort: parseInt(sort, 10)
});

const createCategory = (formData) => (dispatch) => {
  const dto = convertSetCategoryToDB(formData);
  dispatch(actionCategoriesFormSubmitProgress());

  api
    .post('/blog/category/create', dto)
    .then(({ status }) => {
      if (status !== CREATED) {
        throw new Error();
      }

      dispatch(actionCategoriesFormSubmitSuccess('The new category has been successfully created'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionCategoriesFormSubmitFailed(msg));
    });
};

const convertDBCategoryToEdit = ({ title, seo, slug, decor, sort, created, updated, createdAt, updatedAt }) => ({
  title,
  seoTitle: seo.title,
  seoDescription: seo.description,
  slug,
  decor: categoryDecors.find((item) => item.value === decor) || [],
  sort: sort.toString(),
  created,
  updated,
  createdAt,
  updatedAt
});

const getCategory = (categoryId) => (dispatch) => {
  dispatch(actionGetCategoryProgress());

  api
    .get(`/blog/category/${categoryId}`)
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetCategorySuccess(convertDBCategoryToEdit(data)));
    })
    .catch(() => dispatch(actionGetCategoryFailed()));
};

const updateCategory = (categoryId, formData) => (dispatch) => {
  const dto = convertSetCategoryToDB(formData);
  dispatch(actionCategoriesFormSubmitProgress());

  api
    .put(`/blog/category/${categoryId}`, dto)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionCategoriesFormSubmitSuccess('The category has been successfully edited'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionCategoriesFormSubmitFailed(msg));
    });
};

const removeCategory = (categoryId) => (dispatch) => {
  dispatch(actionCategoriesFormSubmitProgress());

  api
    .delete(`/blog/category/${categoryId}`)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionCategoriesFormSubmitSuccess('The category has been successfully removed'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionCategoriesFormSubmitFailed(msg));
    });
};

const findCategoryBySlug = (slug, id) =>
  new Promise((resolve) => {
    api
      .get('/blog/category/findBySlug', { params: { slug, id } })
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve(false);
      })
      .catch(() => resolve(true));
  });

export {
  GET_CATEGORIES_LIST_PROGRESS,
  GET_CATEGORIES_LIST_SUCCESS,
  GET_CATEGORIES_LIST_FAILED,
  CATEGORIES_FORM_SUBMIT_PROGRESS,
  CATEGORIES_FORM_SUBMIT_SUCCESS,
  CATEGORIES_FORM_SUBMIT_FAILED,
  GET_CATEGORY_PROGRESS,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAILED,
  CATEGORIES_CLEAR_STATE,
  actionCategoriesClearState,
  getCategoriesList,
  createCategory,
  getCategory,
  updateCategory,
  removeCategory,
  findCategoryBySlug
};
