import { UNKNOWN } from './textConsts';

export const STATUS_NOT_ACTIVE = 0;
export const STATUS_ACTIVE = 1;
export const STATUS_DELETED = 2;

const userStatuses = [
  { value: STATUS_NOT_ACTIVE, label: 'Not active' },
  { value: STATUS_ACTIVE, label: 'Active' },
  { value: STATUS_DELETED, label: 'Deleted' }
];

export default userStatuses;

export function getStatus(status) {
  const userStatus = userStatuses.find((item) => item.value === status);
  return userStatus || { value: status, label: UNKNOWN };
}

export function getStatusLabel(status) {
  const userStatus = userStatuses.find((item) => item.value === status);
  return userStatus ? userStatus.label : UNKNOWN;
}
