import moment from 'moment';
import api from '../api';
import { CREATED, NO_CONTENT, OK } from '../config/httpStatuses';
import { ERROR_ON_THE_SERVER } from '../config/textConsts';
import { getAges } from '../config/appAges';
import PaginationPageConverter from '../classes/PaginationPageConverter';
import ErrorMessage from '../classes/ErrorMessage';
import { getTypeTitle } from '../config/itemTypes';

const GET_APP_COURSES_LIST_PROGRESS = 'GET_APP_COURSES_LIST_PROGRESS';
const GET_APP_COURSES_LIST_SUCCESS = 'GET_APP_COURSES_LIST_SUCCESS';
const GET_APP_COURSES_LIST_FAILED = 'GET_APP_COURSES_LIST_FAILED';
const APP_COURSES_FORM_SUBMIT_PROGRESS = 'APP_COURSES_FORM_SUBMIT_PROGRESS';
const APP_COURSES_FORM_SUBMIT_SUCCESS = 'APP_COURSES_FORM_SUBMIT_SUCCESS';
const APP_COURSES_FORM_SUBMIT_FAILED = 'APP_COURSES_FORM_SUBMIT_FAILED';
const GET_APP_COURSE_PROGRESS = 'GET_APP_COURSE_PROGRESS';
const GET_APP_COURSE_SUCCESS = 'GET_APP_COURSE_SUCCESS';
const GET_APP_COURSE_FAILED = 'GET_APP_COURSE_FAILED';
const GET_APP_COURSE_AUXILIARY_DATA_PROGRESS = 'GET_APP_COURSE_AUXILIARY_DATA_PROGRESS';
const GET_APP_COURSE_AUXILIARY_DATA_SUCCESS = 'GET_APP_COURSE_AUXILIARY_DATA_SUCCESS';
const GET_APP_COURSE_AUXILIARY_DATA_FAILED = 'GET_APP_COURSE_AUXILIARY_DATA_FAILED';
const APP_COURSE_UPDATE_LESSONS_PROGRESS = 'APP_COURSE_UPDATE_LESSONS_PROGRESS';
const APP_COURSE_UPDATE_LESSONS_SUCCESS = 'APP_COURSE_UPDATE_LESSONS_SUCCESS';
const APP_COURSE_MOVE_ITEM_TO_LESSONS_PROGRESS = 'APP_COURSE_MOVE_ITEM_TO_LESSONS_PROGRESS';
const APP_COURSE_MOVE_ITEM_TO_LESSONS_SUCCESS = 'APP_COURSE_MOVE_ITEM_TO_LESSONS_SUCCESS';
const APP_COURSE_REMOVE_ITEM_FROM_LESSONS_PROGRESS = 'APP_COURSE_REMOVE_ITEM_FROM_LESSONS_PROGRESS';
const APP_COURSE_REMOVE_ITEM_FROM_LESSONS_SUCCESS = 'APP_COURSE_REMOVE_ITEM_FROM_LESSONS_SUCCESS';

const APP_COURSES_CLEAR_STATE = 'APP_COURSES_CLEAR_STATE';

const actionGetCoursesListProgress = () => ({
  type: GET_APP_COURSES_LIST_PROGRESS
});

const actionGetCoursesListSuccess = (payload) => ({
  type: GET_APP_COURSES_LIST_SUCCESS,
  payload
});

const actionGetCoursesListFailed = () => ({
  type: GET_APP_COURSES_LIST_FAILED
});

const actionCoursesFormSubmitProgress = () => ({
  type: APP_COURSES_FORM_SUBMIT_PROGRESS
});

const actionCoursesFormSubmitSuccess = (payload) => ({
  type: APP_COURSES_FORM_SUBMIT_SUCCESS,
  payload
});

const actionCoursesFormSubmitFailed = (payload) => ({
  type: APP_COURSES_FORM_SUBMIT_FAILED,
  payload
});

const actionGetCourseProgress = () => ({
  type: GET_APP_COURSE_PROGRESS
});

const actionGetCourseSuccess = (payload) => ({
  type: GET_APP_COURSE_SUCCESS,
  payload
});

const actionGetCourseFailed = () => ({
  type: GET_APP_COURSE_FAILED
});

const actionGetCourseAuxiliaryDataProgress = () => ({
  type: GET_APP_COURSE_AUXILIARY_DATA_PROGRESS
});

const actionGetCourseAuxiliaryDataSuccess = (payload) => ({
  type: GET_APP_COURSE_AUXILIARY_DATA_SUCCESS,
  payload
});

const actionGetCourseAuxiliaryDataFailed = () => ({
  type: GET_APP_COURSE_AUXILIARY_DATA_FAILED
});

const actionMoveItemToLessonsProgress = () => ({
  type: APP_COURSE_MOVE_ITEM_TO_LESSONS_PROGRESS
});

const actionMoveItemToLessonsSuccess = (payload) => ({
  type: APP_COURSE_MOVE_ITEM_TO_LESSONS_SUCCESS,
  payload
});

const actionUpdateLessonsProgress = () => ({
  type: APP_COURSE_UPDATE_LESSONS_PROGRESS
});

const actionUpdateLessonsSuccess = (payload) => ({
  type: APP_COURSE_UPDATE_LESSONS_SUCCESS,
  payload
});

const actionRemoveItemFromLessonProgress = () => ({
  type: APP_COURSE_REMOVE_ITEM_FROM_LESSONS_PROGRESS
});

const actionRemoveItemFromLessonSuccess = (payload) => ({
  type: APP_COURSE_REMOVE_ITEM_FROM_LESSONS_SUCCESS,
  payload
});

const actionCoursesClearState = () => ({
  type: APP_COURSES_CLEAR_STATE
});

const convertListToTable = (data) =>
  new PaginationPageConverter(data, (doc) => ({
    id: doc.id,
    title: doc.title,
    age: doc.age,
    isPremium: doc.isPremium,
    isHidden: doc.isHidden,
    createdAt: moment(doc.createdAt).format('D MMM YYYY, HH:mm')
  })).getConvertedData();

const getCoursesList = (params) => (dispatch) => {
  dispatch(actionGetCoursesListProgress());

  api
    .get('/app/course', { params })
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetCoursesListSuccess(convertListToTable(data)));
    })
    .catch(() => dispatch(actionGetCoursesListFailed()));
};

const convertLessonsToDB = (newLessons) => {
  const lessons = [];

  for (let i = 0; i < newLessons.length; i += 1) {
    const { id, title, children } = newLessons[i];
    const contentItems = [];

    for (let j = 0; j < children.length; j += 1) {
      const { id: itemId, altTitle } = children[j];
      contentItems.push({ id: itemId, altTitle: altTitle || undefined });
    }

    lessons.push({ id, title, contentItems });
  }

  return lessons;
};

const convertSetCourseToDB = ({
  image,
  ages,
  title,
  description,
  age,
  video,
  lessons,
  speakers,
  isPremium,
  isHidden,
  deletedItems
}) => ({
  image: image || undefined,
  ages: ages.map((item) => item.value),
  title,
  description: description || '',
  age,
  video: video || undefined,
  lessons: Array.isArray(lessons) ? convertLessonsToDB(lessons) : undefined,
  speakers: speakers.map((speaker) => speaker.value),
  isPremium,
  isHidden,
  deletedItems: Array.isArray(deletedItems) && deletedItems.length ? deletedItems : undefined
});

const createCourse = (formData) => (dispatch) => {
  const dto = convertSetCourseToDB(formData);
  dispatch(actionCoursesFormSubmitProgress());

  api
    .post('/app/course/create', dto)
    .then(({ status }) => {
      if (status !== CREATED) {
        throw new Error();
      }

      dispatch(actionCoursesFormSubmitSuccess('The new course has been successfully created'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionCoursesFormSubmitFailed(msg));
    });
};

const getLessonItemIds = (lessons) => {
  const itemIds = [];

  for (const lesson of lessons) {
    const { contentItems } = lesson;

    for (const contentItem of contentItems) {
      const { id } = contentItem;
      itemIds.push(id);
    }
  }

  return itemIds;
};

const convertDBLessonsToTree = (dbLessons) => {
  const tree = [];

  for (let i = 0; i < dbLessons.length; i += 1) {
    const { id, title, contentItems } = dbLessons[i];
    const children = [];

    for (const contentItem of contentItems) {
      const { id: itemId, type, itemTitle, altTitle } = contentItem;
      children.push({
        id: itemId,
        title: altTitle || itemTitle,
        itemTitle,
        altTitle: altTitle || '',
        type,
        subtitle: `Type: ${getTypeTitle(type)}`,
        isLesson: false
      });
    }

    tree.push({
      id,
      title,
      subtitle: `Lesson ${i + 1}`,
      isLesson: true,
      children
    });
  }

  return tree;
};

const convertDBCourseToEdit = ({
  image,
  ages,
  title,
  description,
  age,
  video,
  lessons,
  speakers,
  isPremium,
  isHidden,
  created,
  updated,
  createdAt,
  updatedAt
}) => ({
  image: image || null,
  ages: getAges(ages),
  title,
  description,
  age,
  video: video || '',
  lessonIds: lessons.map((lesson) => lesson.id),
  lessonItemIds: getLessonItemIds(lessons),
  tree: convertDBLessonsToTree(lessons),
  speakers: speakers.map((speaker) => ({ value: speaker.id, label: speaker.name })),
  isPremium,
  isHidden,
  created,
  updated,
  createdAt,
  updatedAt
});

const getCourse = (courseId) => (dispatch) => {
  dispatch(actionGetCourseProgress());

  api
    .get(`/app/course/${courseId}`)
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetCourseSuccess(convertDBCourseToEdit(data)));
    })
    .catch(() => dispatch(actionGetCourseFailed()));
};

const updateCourse = (courseId, formData) => (dispatch) => {
  const dto = convertSetCourseToDB(formData);
  dispatch(actionCoursesFormSubmitProgress());

  api
    .put(`/app/course/${courseId}`, dto)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionCoursesFormSubmitSuccess('The course has been successfully edited'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionCoursesFormSubmitFailed(msg));
    });
};

const removeCourse = (courseId) => (dispatch) => {
  dispatch(actionCoursesFormSubmitProgress());

  api
    .delete(`/app/course/${courseId}`)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionCoursesFormSubmitSuccess('The course has been successfully removed'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionCoursesFormSubmitFailed(msg));
    });
};

const getAuxiliaryData = (courseId) => (dispatch) => {
  dispatch(actionGetCourseAuxiliaryDataProgress());

  api
    .get(`/app/course/auxiliaryData${courseId ? `/${courseId}` : ''}`)
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(
        actionGetCourseAuxiliaryDataSuccess({
          speakers: data.speakers.map((speaker) => ({ value: speaker.id, label: speaker.name })),
          items: data.items.map((item) => ({ id: item.id, title: item.title, type: item.type }))
        })
      );
    })
    .catch(() => dispatch(actionGetCourseAuxiliaryDataFailed()));
};

const generateLessonId = () =>
  new Promise((resolve, reject) => {
    api
      .get('/app/course/generateLessonId')
      .then(({ status, data }) => {
        if (status !== OK) {
          throw new Error();
        }

        resolve(data);
      })
      .catch(({ response }) => {
        let msg = ERROR_ON_THE_SERVER;

        if (response) {
          const { message, error } = response.data;
          msg = new ErrorMessage(message, error).getMessage();
        }

        reject(msg);
      });
  });

const moveItemToLessons = (lessonId, item) => (dispatch) => {
  dispatch(actionMoveItemToLessonsProgress());

  setTimeout(() => {
    dispatch(actionMoveItemToLessonsSuccess({ lessonId, item }));
  }, 250);
};

const updateLessons = (lessons) => (dispatch) => {
  dispatch(actionUpdateLessonsProgress());

  setTimeout(() => {
    dispatch(actionUpdateLessonsSuccess({ lessons }));
  }, 250);
};

const removeItemFromLesson = (item) => (dispatch) => {
  dispatch(actionRemoveItemFromLessonProgress());

  setTimeout(() => {
    dispatch(actionRemoveItemFromLessonSuccess({ item }));
  }, 250);
};

export {
  GET_APP_COURSES_LIST_PROGRESS,
  GET_APP_COURSES_LIST_SUCCESS,
  GET_APP_COURSES_LIST_FAILED,
  APP_COURSES_FORM_SUBMIT_PROGRESS,
  APP_COURSES_FORM_SUBMIT_SUCCESS,
  APP_COURSES_FORM_SUBMIT_FAILED,
  GET_APP_COURSE_PROGRESS,
  GET_APP_COURSE_SUCCESS,
  GET_APP_COURSE_FAILED,
  GET_APP_COURSE_AUXILIARY_DATA_PROGRESS,
  GET_APP_COURSE_AUXILIARY_DATA_SUCCESS,
  GET_APP_COURSE_AUXILIARY_DATA_FAILED,
  APP_COURSE_UPDATE_LESSONS_PROGRESS,
  APP_COURSE_UPDATE_LESSONS_SUCCESS,
  APP_COURSE_MOVE_ITEM_TO_LESSONS_PROGRESS,
  APP_COURSE_MOVE_ITEM_TO_LESSONS_SUCCESS,
  APP_COURSE_REMOVE_ITEM_FROM_LESSONS_PROGRESS,
  APP_COURSE_REMOVE_ITEM_FROM_LESSONS_SUCCESS,
  APP_COURSES_CLEAR_STATE,
  actionCoursesClearState,
  getCoursesList,
  createCourse,
  getCourse,
  updateCourse,
  removeCourse,
  getAuxiliaryData,
  generateLessonId,
  moveItemToLessons,
  updateLessons,
  removeItemFromLesson
};
