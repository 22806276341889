import update from 'immutability-helper';
import { PROGRESS, SUCCESS, FAILED } from '../config/types';
import {
  SETTINGS_FORM_SUBMIT_PROGRESS,
  SETTINGS_FORM_SUBMIT_SUCCESS,
  SETTINGS_FORM_SUBMIT_FAILED,
  GET_SETTINGS_PROGRESS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILED,
  SETTINGS_CLEAR_STATE
} from '../actions/SettingsAction';

const initialState = {
  loadActiveStatus: '',
  submitStatus: '',
  message: '',
  active: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SETTINGS_FORM_SUBMIT_PROGRESS: {
      return update(state, { submitStatus: { $set: PROGRESS }, message: { $set: '' } });
    }
    case SETTINGS_FORM_SUBMIT_SUCCESS: {
      return update(state, { submitStatus: { $set: SUCCESS }, message: { $set: action.payload } });
    }
    case SETTINGS_FORM_SUBMIT_FAILED: {
      return update(state, { submitStatus: { $set: FAILED }, message: { $set: action.payload } });
    }
    case GET_SETTINGS_PROGRESS: {
      return update(state, {
        active: { $set: {} },
        loadActiveStatus: { $set: PROGRESS }
      });
    }
    case GET_SETTINGS_SUCCESS: {
      return update(state, {
        active: { $set: action.payload },
        loadActiveStatus: { $set: SUCCESS }
      });
    }
    case GET_SETTINGS_FAILED: {
      return update(state, {
        active: { $set: {} },
        loadActiveStatus: { $set: FAILED }
      });
    }
    case SETTINGS_CLEAR_STATE: {
      return update(state, {
        loadActiveStatus: { $set: '' },
        submitStatus: { $set: '' },
        message: { $set: '' },
        active: { $set: {} }
      });
    }
    default:
      return state;
  }
}
