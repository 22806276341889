import axios from 'axios';
import TokensService from './tokensService';
import config from './config/config';
import store from './config/configureStore';
import { AUTH_TOKEN_RENEW_PROGRESS, AUTH_TOKEN_RENEW_SUCCESS, AUTH_LOGOUT } from './config/types';

const tokensService = TokensService.getService();
const requestHandler = (axiosConfig, useAccessToken = true) => {
  const conf = axiosConfig;
  const token = useAccessToken ? tokensService.getAccessToken() : tokensService.getRefreshToken();

  if (token) {
    conf.headers.Authorization = `Bearer ${token}`;
  }

  conf.headers['Content-Type'] = 'application/json';

  return conf;
};

axios.defaults.baseURL = config.urls.api;

axios.interceptors.request.use(
  (axiosConfig) => requestHandler(axiosConfig),
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    const renewTokenUrl = '/auth/renew-token';

    if (error.response.status === 401 && originalRequest.url.indexOf(renewTokenUrl) !== -1) {
      store.dispatch({ type: AUTH_LOGOUT });
      return Promise.reject(error);
    }

    // eslint-disable-next-line no-underscore-dangle
    if (error.response.status === 401 && !originalRequest._retry) {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;

      const instance = axios.create();
      instance.interceptors.request.use(
        (axiosConfig) => requestHandler(axiosConfig, false),
        (err) => {
          store.dispatch({ type: AUTH_LOGOUT });
          Promise.reject(err);
        }
      );

      store.dispatch({ type: AUTH_TOKEN_RENEW_PROGRESS });

      return instance
        .post(`${config.urls.api}${renewTokenUrl}`)
        .then((response) => {
          const { status, data } = response;

          if (status !== 200) {
            throw new Error();
          }

          store.dispatch({ type: AUTH_TOKEN_RENEW_SUCCESS, payload: data });
          return axios(originalRequest);
        })
        .catch(() => {
          store.dispatch({ type: AUTH_LOGOUT });
          return Promise.reject(error);
        });
    }

    return Promise.reject(error);
  }
);

export default axios;
