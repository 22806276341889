import update from 'immutability-helper';
import { PROGRESS, SUCCESS, FAILED } from '../config/types';
import {
  GET_ARTICLES_LIST_PROGRESS,
  GET_ARTICLES_LIST_SUCCESS,
  GET_ARTICLES_LIST_FAILED,
  ARTICLES_FORM_SUBMIT_PROGRESS,
  ARTICLES_FORM_SUBMIT_SUCCESS,
  ARTICLES_FORM_SUBMIT_FAILED,
  GET_ARTICLE_PROGRESS,
  GET_ARTICLE_SUCCESS,
  GET_ARTICLE_FAILED,
  GET_ARTICLE_AUXILIARY_DATA_PROGRESS,
  GET_ARTICLE_AUXILIARY_DATA_SUCCESS,
  GET_ARTICLE_AUXILIARY_DATA_FAILED,
  ARTICLES_CLEAR_STATE
} from '../actions/ArticlesAction';

const initialState = {
  loadListStatus: '',
  loadActiveStatus: '',
  loadAuxiliaryStatus: '',
  submitStatus: '',
  message: '',
  list: [],
  pagination: {},
  active: {},
  auxiliary: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ARTICLES_LIST_PROGRESS: {
      return update(state, {
        list: { $set: [] },
        pagination: { $set: {} },
        loadListStatus: { $set: PROGRESS }
      });
    }
    case GET_ARTICLES_LIST_SUCCESS: {
      return update(state, {
        list: { $set: action.payload.list },
        pagination: { $set: action.payload.pagination },
        loadListStatus: { $set: SUCCESS }
      });
    }
    case GET_ARTICLES_LIST_FAILED: {
      return update(state, {
        list: { $set: [] },
        pagination: { $set: {} },
        loadListStatus: { $set: FAILED }
      });
    }
    case ARTICLES_FORM_SUBMIT_PROGRESS: {
      return update(state, { submitStatus: { $set: PROGRESS }, message: { $set: '' } });
    }
    case ARTICLES_FORM_SUBMIT_SUCCESS: {
      return update(state, { submitStatus: { $set: SUCCESS }, message: { $set: action.payload } });
    }
    case ARTICLES_FORM_SUBMIT_FAILED: {
      return update(state, { submitStatus: { $set: FAILED }, message: { $set: action.payload } });
    }
    case GET_ARTICLE_PROGRESS: {
      return update(state, {
        active: { $set: {} },
        loadActiveStatus: { $set: PROGRESS }
      });
    }
    case GET_ARTICLE_SUCCESS: {
      return update(state, {
        active: { $set: action.payload },
        loadActiveStatus: { $set: SUCCESS }
      });
    }
    case GET_ARTICLE_FAILED: {
      return update(state, {
        active: { $set: {} },
        loadActiveStatus: { $set: FAILED }
      });
    }
    case GET_ARTICLE_AUXILIARY_DATA_PROGRESS: {
      return update(state, { loadAuxiliaryStatus: { $set: PROGRESS }, auxiliary: { $set: {} } });
    }
    case GET_ARTICLE_AUXILIARY_DATA_SUCCESS: {
      return update(state, { loadAuxiliaryStatus: { $set: SUCCESS }, auxiliary: { $set: { ...action.payload } } });
    }
    case GET_ARTICLE_AUXILIARY_DATA_FAILED: {
      return update(state, { loadAuxiliaryStatus: { $set: FAILED }, auxiliary: { $set: {} } });
    }
    case ARTICLES_CLEAR_STATE: {
      return update(state, {
        loadListStatus: { $set: '' },
        loadActiveStatus: { $set: '' },
        loadAuxiliaryStatus: { $set: '' },
        submitStatus: { $set: '' },
        message: { $set: '' },
        list: { $set: [] },
        pagination: { $set: {} },
        active: { $set: {} },
        auxiliary: { $set: {} }
      });
    }
    default:
      return state;
  }
}
