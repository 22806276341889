import jwtDecode from 'jwt-decode';
import api from '../api';
import store from '../config/configureStore';
import {
  AUTH_PROFILE_PROGRESS,
  AUTH_PROFILE_SUCCESS,
  AUTH_PROFILE_FAILED,
  AUTH_TOKEN_RENEW_FAILED,
  AUTH_SING_IN_PROGRESS,
  AUTH_SING_IN_SUCCESS,
  AUTH_SING_IN_FAILED
} from '../config/types';
import UserRoles from '../classes/UserRoles';

const authStartFormSubmit = () => ({
  type: AUTH_SING_IN_PROGRESS
});

const authFormSubmitSuccess = (payload) => ({
  type: AUTH_SING_IN_SUCCESS,
  payload
});

const authFormSubmitFailed = () => ({
  type: AUTH_SING_IN_FAILED
});

const authStartProfileSubmit = () => ({
  type: AUTH_PROFILE_PROGRESS
});

const authProfileSubmitSuccess = (payload) => ({
  type: AUTH_PROFILE_SUCCESS,
  payload
});

const authProfileSubmitFailed = () => ({
  type: AUTH_PROFILE_FAILED
});

const authTokenRenewSubmitFailed = () => ({
  type: AUTH_TOKEN_RENEW_FAILED
});

const convertSetAuthToDB = (data) => ({
  email: data.email,
  password: data.password,
  application: 0
});

const singIn = (obj) => (dispatch) => {
  const dto = convertSetAuthToDB(obj);
  dispatch(authStartFormSubmit());

  api
    .post('/auth/login', dto)
    .then(({ status, data }) => {
      if (status !== 200) {
        throw new Error();
      }

      dispatch(authFormSubmitSuccess(data));
    })
    .catch(() => dispatch(authFormSubmitFailed()));
};

const getUserProfile = () => (dispatch) => {
  dispatch(authStartProfileSubmit());

  Promise.resolve(store.getState().auth)
    .then(({ tokens }) => {
      if (!tokens || !Object.hasOwnProperty.call(tokens, 'access_token')) {
        throw new Error();
      }
      const { access_token: accessToken } = tokens;
      return jwtDecode(accessToken);
    })
    .then(({ id }) => api.get(`/user/profile/${id}`))
    .then(({ status, data }) => {
      if (status !== 200) {
        throw new Error();
      }

      const profile = {
        ...data,
        roleDescription: new UserRoles(data.roles).getTextRole(true)
      };

      dispatch(authProfileSubmitSuccess(profile));
    })
    .catch(() => dispatch(authProfileSubmitFailed()));
};

export {
  authProfileSubmitSuccess,
  authStartFormSubmit,
  authFormSubmitSuccess,
  authFormSubmitFailed,
  authTokenRenewSubmitFailed,
  getUserProfile,
  singIn
};
