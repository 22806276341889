import moment from 'moment';
import api from '../api';
import { CREATED, NO_CONTENT, OK } from '../config/httpStatuses';
import { ERROR_ON_THE_SERVER } from '../config/textConsts';
import PaginationPageConverter from '../classes/PaginationPageConverter';
import ErrorMessage from '../classes/ErrorMessage';
import appPageTypes from '../config/appPageTypes';

const GET_APP_PAGES_LIST_PROGRESS = 'GET_APP_PAGES_LIST_PROGRESS';
const GET_APP_PAGES_LIST_SUCCESS = 'GET_APP_PAGES_LIST_SUCCESS';
const GET_APP_PAGES_LIST_FAILED = 'GET_APP_PAGES_LIST_FAILED';
const APP_PAGES_FORM_SUBMIT_PROGRESS = 'APP_PAGES_FORM_SUBMIT_PROGRESS';
const APP_PAGES_FORM_SUBMIT_SUCCESS = 'APP_PAGES_FORM_SUBMIT_SUCCESS';
const APP_PAGES_FORM_SUBMIT_FAILED = 'APP_PAGES_FORM_SUBMIT_FAILED';
const GET_APP_PAGE_PROGRESS = 'GET_APP_PAGE_PROGRESS';
const GET_APP_PAGE_SUCCESS = 'GET_APP_PAGE_SUCCESS';
const GET_ALL_PAGE_FAILED = 'GET_ALL_PAGE_FAILED';
const APP_PAGES_CLEAR_STATE = 'APP_PAGES_CLEAR_STATE';

const actionGetAppPagesListProgress = () => ({
  type: GET_APP_PAGES_LIST_PROGRESS
});

const actionGetAppPagesListSuccess = (payload) => ({
  type: GET_APP_PAGES_LIST_SUCCESS,
  payload
});

const actionGetAppPagesListFailed = () => ({
  type: GET_APP_PAGES_LIST_FAILED
});

const actionAppPagesFormSubmitProgress = () => ({
  type: APP_PAGES_FORM_SUBMIT_PROGRESS
});

const actionAppPagesFormSubmitSuccess = (payload) => ({
  type: APP_PAGES_FORM_SUBMIT_SUCCESS,
  payload
});

const actionAppPagesFormSubmitFailed = (payload) => ({
  type: APP_PAGES_FORM_SUBMIT_FAILED,
  payload
});

const actionGetAppPageProgress = () => ({
  type: GET_APP_PAGE_PROGRESS
});

const actionGetAppPageSuccess = (payload) => ({
  type: GET_APP_PAGE_SUCCESS,
  payload
});

const actionGetAppPageFailed = () => ({
  type: GET_ALL_PAGE_FAILED
});

const actionAppPagesClearState = () => ({
  type: APP_PAGES_CLEAR_STATE
});

const convertListToTable = (data) =>
  new PaginationPageConverter(data, (doc) => ({
    id: doc.id,
    title: doc.title,
    type: doc.type,
    createdAt: moment(doc.createdAt).format('D MMM YYYY, HH:mm')
  })).getConvertedData();

const getPagesList = (params) => (dispatch) => {
  dispatch(actionGetAppPagesListProgress());

  api
    .get('/app/page', { params })
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetAppPagesListSuccess(convertListToTable(data)));
    })
    .catch(() => dispatch(actionGetAppPagesListFailed()));
};

const convertSetPageToDB = ({ image, title, content, type }) => ({
  image: image || undefined,
  title,
  content: content || '',
  type: parseInt(type.value, 10)
});

const createPage = (formData) => (dispatch) => {
  const dto = convertSetPageToDB(formData);
  dispatch(actionAppPagesFormSubmitProgress());

  api
    .post('/app/page/create', dto)
    .then(({ status }) => {
      if (status !== CREATED) {
        throw new Error();
      }

      dispatch(actionAppPagesFormSubmitSuccess('The new page has been successfully created'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionAppPagesFormSubmitFailed(msg));
    });
};

const convertDBPageToEdit = ({ image, title, content, type, created, updated, createdAt, updatedAt }) => ({
  image: image || null,
  title,
  content,
  type: appPageTypes.find((item) => item.value === type),
  created,
  updated,
  createdAt,
  updatedAt
});

const getPage = (pageId) => (dispatch) => {
  dispatch(actionGetAppPageProgress());

  api
    .get(`/app/page/${pageId}`)
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetAppPageSuccess(convertDBPageToEdit(data)));
    })
    .catch(() => dispatch(actionGetAppPageFailed()));
};

const updatePage = (pageId, formData) => (dispatch) => {
  const dto = convertSetPageToDB(formData);
  dispatch(actionAppPagesFormSubmitProgress());

  api
    .put(`/app/page/${pageId}`, dto)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionAppPagesFormSubmitSuccess('The page has been successfully edited'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionAppPagesFormSubmitFailed(msg));
    });
};

const removePage = (pageId) => (dispatch) => {
  dispatch(actionAppPagesFormSubmitProgress());

  api
    .delete(`/app/page/${pageId}`)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionAppPagesFormSubmitSuccess('The page has been successfully removed'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionAppPagesFormSubmitFailed(msg));
    });
};

const findPageByType = (type, id) =>
  new Promise((resolve) => {
    api
      .get('/app/page/findByType', { params: { type, id } })
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve(false);
      })
      .catch(() => resolve(true));
  });

export {
  GET_APP_PAGES_LIST_PROGRESS,
  GET_APP_PAGES_LIST_SUCCESS,
  GET_APP_PAGES_LIST_FAILED,
  APP_PAGES_FORM_SUBMIT_PROGRESS,
  APP_PAGES_FORM_SUBMIT_SUCCESS,
  APP_PAGES_FORM_SUBMIT_FAILED,
  GET_APP_PAGE_PROGRESS,
  GET_APP_PAGE_SUCCESS,
  GET_ALL_PAGE_FAILED,
  APP_PAGES_CLEAR_STATE,
  actionAppPagesClearState,
  getPagesList,
  createPage,
  getPage,
  updatePage,
  removePage,
  findPageByType
};
