import moment from 'moment';
import UserRoles from './UserRoles';
import config from '../config/config';

class User {
  constructor(user) {
    this.id = user.id;
    this.firstname = user.firstname;
    this.surname = user.surname;
    this.gender = user.gender;
    this.isActive = user.isActive;
    this.email = user.email || undefined;
    this.image = user.image || undefined;
    this.birthday = user.birthday || undefined;
    this.roles = user.roles;
    this.createdAt = user.createdAt;
    this.updatedAt = user.updatedAt;
    this.userRoles = new UserRoles(user.roles);
  }

  checkImagesIsExists() {
    return typeof this.image !== 'undefined';
  }

  get name() {
    return `${this.firstname}${this.surname ? ` ${this.surname}` : ''}`;
  }

  get thumbnailImages() {
    if (!this.checkImagesIsExists()) {
      return null;
    }

    return {
      avif: `${config.urls.cdn}${this.image.thumbnail.avif}`,
      webp: `${config.urls.cdn}${this.image.thumbnail.webp}`,
      jpg: `${config.urls.cdn}${this.image.thumbnail.jpg}`
    };
  }

  get mediumImages() {
    if (!this.checkImagesIsExists()) {
      return null;
    }

    return {
      avif: `${config.urls.cdn}${this.image.medium.avif}`,
      webp: `${config.urls.cdn}${this.image.medium.webp}`,
      jpg: `${config.urls.cdn}${this.image.medium.jpg}`
    };
  }

  get largeImages() {
    if (!this.checkImagesIsExists()) {
      return null;
    }

    return {
      avif: `${config.urls.cdn}${this.image.large.avif}`,
      webp: `${config.urls.cdn}${this.image.large.webp}`,
      jpg: `${config.urls.cdn}${this.image.large.jpg}`
    };
  }

  get createdDate() {
    return moment(this.createdAt).format('D MMM YYYY, HH:mm');
  }
}

export default User;
