import moment from 'moment';
import api from '../api';
import { CREATED, NO_CONTENT, OK } from '../config/httpStatuses';
import { ERROR_ON_THE_SERVER } from '../config/textConsts';
import PaginationPageConverter from '../classes/PaginationPageConverter';
import ErrorMessage from '../classes/ErrorMessage';

const GET_APP_PASTIME_CATEGORIES_LIST_PROGRESS = 'GET_APP_PASTIME_CATEGORIES_LIST_PROGRESS';
const GET_APP_PASTIME_CATEGORIES_LIST_SUCCESS = 'GET_APP_PASTIME_CATEGORIES_LIST_SUCCESS';
const GET_APP_PASTIME_CATEGORIES_LIST_FAILED = 'GET_APP_PASTIME_CATEGORIES_LIST_FAILED';
const APP_PASTIME_CATEGORIES_FORM_SUBMIT_PROGRESS = 'APP_PASTIME_CATEGORIES_FORM_SUBMIT_PROGRESS';
const APP_PASTIME_CATEGORIES_FORM_SUBMIT_SUCCESS = 'APP_PASTIME_CATEGORIES_FORM_SUBMIT_SUCCESS';
const APP_PASTIME_CATEGORIES_FORM_SUBMIT_FAILED = 'APP_PASTIME_CATEGORIES_FORM_SUBMIT_FAILED';
const GET_APP_PASTIME_CATEGORY_PROGRESS = 'GET_APP_PASTIME_CATEGORY_PROGRESS';
const GET_APP_PASTIME_CATEGORY_SUCCESS = 'GET_APP_PASTIME_CATEGORY_SUCCESS';
const GET_APP_PASTIME_CATEGORY_FAILED = 'GET_APP_PASTIME_CATEGORY_FAILED';
const APP_PASTIME_CATEGORIES_CLEAR_STATE = 'APP_PASTIME_CATEGORIES_CLEAR_STATE';

const actionGetAppPastimeCategoriesListProgress = () => ({
  type: GET_APP_PASTIME_CATEGORIES_LIST_PROGRESS
});

const actionGetAppPastimeCategoriesListSuccess = (payload) => ({
  type: GET_APP_PASTIME_CATEGORIES_LIST_SUCCESS,
  payload
});

const actionGetAppPastimeCategoriesListFailed = () => ({
  type: GET_APP_PASTIME_CATEGORIES_LIST_FAILED
});

const actionAppPastimeCategoriesFormSubmitProgress = () => ({
  type: APP_PASTIME_CATEGORIES_FORM_SUBMIT_PROGRESS
});

const actionAppPastimeCategoriesFormSubmitSuccess = (payload) => ({
  type: APP_PASTIME_CATEGORIES_FORM_SUBMIT_SUCCESS,
  payload
});

const actionAppPastimeCategoriesFormSubmitFailed = (payload) => ({
  type: APP_PASTIME_CATEGORIES_FORM_SUBMIT_FAILED,
  payload
});

const actionGetAppPastimeCategoryProgress = () => ({
  type: GET_APP_PASTIME_CATEGORY_PROGRESS
});

const actionGetAppPastimeCategorySuccess = (payload) => ({
  type: GET_APP_PASTIME_CATEGORY_SUCCESS,
  payload
});

const actionGetAppPastimeCategoryFailed = () => ({
  type: GET_APP_PASTIME_CATEGORY_FAILED
});

const actionAppPastimeCategoriesClearState = () => ({
  type: APP_PASTIME_CATEGORIES_CLEAR_STATE
});

const convertListToTable = (data) =>
  new PaginationPageConverter(data, (doc) => ({
    id: doc.id,
    title: doc.title,
    sorting: doc.sort,
    createdAt: moment(doc.createdAt).format('D MMM YYYY, HH:mm')
  })).getConvertedData();

const getPastimeCategoriesList = (params) => (dispatch) => {
  dispatch(actionGetAppPastimeCategoriesListProgress());

  api
    .get('/app/pastime-category', { params })
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetAppPastimeCategoriesListSuccess(convertListToTable(data)));
    })
    .catch(() => dispatch(actionGetAppPastimeCategoriesListFailed()));
};

const convertSetPastimeCategoryToDB = ({ title, sort }) => ({
  title,
  sort: parseInt(sort, 10)
});

const createPastimeCategory = (formData) => (dispatch) => {
  const dto = convertSetPastimeCategoryToDB(formData);
  dispatch(actionAppPastimeCategoriesFormSubmitProgress());

  api
    .post('/app/pastime-category/create', dto)
    .then(({ status }) => {
      if (status !== CREATED) {
        throw new Error();
      }

      dispatch(actionAppPastimeCategoriesFormSubmitSuccess('The new category has been successfully created'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionAppPastimeCategoriesFormSubmitFailed(msg));
    });
};

const convertDBPastimeCategoryToEdit = ({ title, sort, created, updated, createdAt, updatedAt }) => ({
  title,
  sort: sort.toString(),
  created,
  updated,
  createdAt,
  updatedAt
});

const getPastimeCategory = (categoryId) => (dispatch) => {
  dispatch(actionGetAppPastimeCategoryProgress());

  api
    .get(`/app/pastime-category/${categoryId}`)
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetAppPastimeCategorySuccess(convertDBPastimeCategoryToEdit(data)));
    })
    .catch(() => dispatch(actionGetAppPastimeCategoryFailed()));
};

const updatePastimeCategory = (categoryId, formData) => (dispatch) => {
  const dto = convertSetPastimeCategoryToDB(formData);
  dispatch(actionAppPastimeCategoriesFormSubmitProgress());

  api
    .put(`/app/pastime-category/${categoryId}`, dto)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionAppPastimeCategoriesFormSubmitSuccess('The category has been successfully edited'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionAppPastimeCategoriesFormSubmitFailed(msg));
    });
};

const removePastimeCategory = (categoryId) => (dispatch) => {
  dispatch(actionAppPastimeCategoriesFormSubmitProgress());

  api
    .delete(`/app/pastime-category/${categoryId}`)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionAppPastimeCategoriesFormSubmitSuccess('The category has been successfully removed'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionAppPastimeCategoriesFormSubmitFailed(msg));
    });
};

export {
  GET_APP_PASTIME_CATEGORIES_LIST_PROGRESS,
  GET_APP_PASTIME_CATEGORIES_LIST_SUCCESS,
  GET_APP_PASTIME_CATEGORIES_LIST_FAILED,
  APP_PASTIME_CATEGORIES_FORM_SUBMIT_PROGRESS,
  APP_PASTIME_CATEGORIES_FORM_SUBMIT_SUCCESS,
  APP_PASTIME_CATEGORIES_FORM_SUBMIT_FAILED,
  GET_APP_PASTIME_CATEGORY_PROGRESS,
  GET_APP_PASTIME_CATEGORY_SUCCESS,
  GET_APP_PASTIME_CATEGORY_FAILED,
  APP_PASTIME_CATEGORIES_CLEAR_STATE,
  actionAppPastimeCategoriesClearState,
  getPastimeCategoriesList,
  createPastimeCategory,
  getPastimeCategory,
  updatePastimeCategory,
  removePastimeCategory
};
