import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import thunk from 'redux-thunk';
import reducers from '../reducers';

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const setAuthState = (state) => {
  try {
    localStorage.setItem('tokens', JSON.stringify((state.auth || {}).tokens));
  } catch (err) {
    return undefined;
  }
};

const store = createStore(
  combineReducers({
    reduxFormReducer,
    ...reducers
  }),
  {},
  storeEnhancers(applyMiddleware(thunk))
);

store.subscribe(() => {
  setAuthState(store.getState());
});

export default store;
