import moment from 'moment';
import api from '../api';
import { CREATED, NO_CONTENT, OK } from '../config/httpStatuses';
import { ERROR_ON_THE_SERVER } from '../config/textConsts';
import PaginationPageConverter from '../classes/PaginationPageConverter';
import ErrorMessage from '../classes/ErrorMessage';

const GET_APP_ARTICLES_LIST_PROGRESS = 'GET_APP_ARTICLES_LIST_PROGRESS';
const GET_APP_ARTICLES_LIST_SUCCESS = 'GET_APP_ARTICLES_LIST_SUCCESS';
const GET_APP_ARTICLES_LIST_FAILED = 'GET_APP_ARTICLES_LIST_FAILED';
const APP_ARTICLES_FORM_SUBMIT_PROGRESS = 'APP_ARTICLES_FORM_SUBMIT_PROGRESS';
const APP_ARTICLES_FORM_SUBMIT_SUCCESS = 'APP_ARTICLES_FORM_SUBMIT_SUCCESS';
const APP_ARTICLES_FORM_SUBMIT_FAILED = 'APP_ARTICLES_FORM_SUBMIT_FAILED';
const GET_APP_ARTICLE_PROGRESS = 'GET_APP_ARTICLE_PROGRESS';
const GET_APP_ARTICLE_SUCCESS = 'GET_APP_ARTICLE_SUCCESS';
const GET_ALL_ARTICLE_FAILED = 'GET_ALL_ARTICLE_FAILED';
const APP_ARTICLES_CLEAR_STATE = 'APP_ARTICLES_CLEAR_STATE';

const actionGetAppArticlesListProgress = () => ({
  type: GET_APP_ARTICLES_LIST_PROGRESS
});

const actionGetAppArticlesListSuccess = (payload) => ({
  type: GET_APP_ARTICLES_LIST_SUCCESS,
  payload
});

const actionGetAppArticlesListFailed = () => ({
  type: GET_APP_ARTICLES_LIST_FAILED
});

const actionAppArticlesFormSubmitProgress = () => ({
  type: APP_ARTICLES_FORM_SUBMIT_PROGRESS
});

const actionAppArticlesFormSubmitSuccess = (payload) => ({
  type: APP_ARTICLES_FORM_SUBMIT_SUCCESS,
  payload
});

const actionAppArticlesFormSubmitFailed = (payload) => ({
  type: APP_ARTICLES_FORM_SUBMIT_FAILED,
  payload
});

const actionGetAppArticleProgress = () => ({
  type: GET_APP_ARTICLE_PROGRESS
});

const actionGetAppArticleSuccess = (payload) => ({
  type: GET_APP_ARTICLE_SUCCESS,
  payload
});

const actionGetAppArticleFailed = () => ({
  type: GET_ALL_ARTICLE_FAILED
});

const actionAppArticlesClearState = () => ({
  type: APP_ARTICLES_CLEAR_STATE
});

const convertListToTable = (data) =>
  new PaginationPageConverter(data, (doc) => ({
    id: doc.id,
    title: doc.title,
    createdAt: moment(doc.createdAt).format('D MMM YYYY, HH:mm')
  })).getConvertedData();

const getArticlesList = (params) => (dispatch) => {
  dispatch(actionGetAppArticlesListProgress());

  api
    .get('/app/article', { params })
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetAppArticlesListSuccess(convertListToTable(data)));
    })
    .catch(() => dispatch(actionGetAppArticlesListFailed()));
};

const convertSetArticleToDB = ({ image, title, content }) => ({
  image: image || undefined,
  title,
  content: content || ''
});

const createArticle = (formData) => (dispatch) => {
  const dto = convertSetArticleToDB(formData);
  dispatch(actionAppArticlesFormSubmitProgress());

  api
    .post('/app/article/create', dto)
    .then(({ status }) => {
      if (status !== CREATED) {
        throw new Error();
      }

      dispatch(actionAppArticlesFormSubmitSuccess('The new article has been successfully created'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionAppArticlesFormSubmitFailed(msg));
    });
};

const convertDBArticleToEdit = ({ image, title, content, created, updated, createdAt, updatedAt }) => ({
  image: image || null,
  title,
  content,
  created,
  updated,
  createdAt,
  updatedAt
});

const getArticle = (articleId) => (dispatch) => {
  dispatch(actionGetAppArticleProgress());

  api
    .get(`/app/article/${articleId}`)
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetAppArticleSuccess(convertDBArticleToEdit(data)));
    })
    .catch(() => dispatch(actionGetAppArticleFailed()));
};

const updateArticle = (articleId, formData) => (dispatch) => {
  const dto = convertSetArticleToDB(formData);
  dispatch(actionAppArticlesFormSubmitProgress());

  api
    .put(`/app/article/${articleId}`, dto)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionAppArticlesFormSubmitSuccess('The article has been successfully edited'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionAppArticlesFormSubmitFailed(msg));
    });
};

const removeArticle = (articleId) => (dispatch) => {
  dispatch(actionAppArticlesFormSubmitProgress());

  api
    .delete(`/app/article/${articleId}`)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionAppArticlesFormSubmitSuccess('The article has been successfully removed'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionAppArticlesFormSubmitFailed(msg));
    });
};

export {
  GET_APP_ARTICLES_LIST_PROGRESS,
  GET_APP_ARTICLES_LIST_SUCCESS,
  GET_APP_ARTICLES_LIST_FAILED,
  APP_ARTICLES_FORM_SUBMIT_PROGRESS,
  APP_ARTICLES_FORM_SUBMIT_SUCCESS,
  APP_ARTICLES_FORM_SUBMIT_FAILED,
  GET_APP_ARTICLE_PROGRESS,
  GET_APP_ARTICLE_SUCCESS,
  GET_ALL_ARTICLE_FAILED,
  APP_ARTICLES_CLEAR_STATE,
  actionAppArticlesClearState,
  getArticlesList,
  createArticle,
  getArticle,
  updateArticle,
  removeArticle
};
