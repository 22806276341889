import moment from 'moment';
import api from '../api';
import { CREATED, NO_CONTENT, OK } from '../config/httpStatuses';
import { ROLE_BLOG_AUTHOR, prepareToUpdateRoles, convertRolesToEdit } from '../config/roles';
import genders from '../config/genders';
import accessTypes from '../config/accessTypes';
import store from '../config/configureStore';
import { authProfileSubmitSuccess, getUserProfile } from './AuthAction';
import PaginationPageConverter from '../classes/PaginationPageConverter';
import { ERROR_ON_THE_SERVER, UNKNOWN } from '../config/textConsts';
import UserRoles from '../classes/UserRoles';
import ErrorMessage from '../classes/ErrorMessage';
import { getStatus } from '../config/userStatuses';

const GET_USERS_LIST_PROGRESS = 'GET_USERS_LIST_PROGRESS';
const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS';
const GET_USERS_LIST_FAILED = 'GET_USERS_LIST_FAILED';
const USERS_FORM_SUBMIT_PROGRESS = 'USERS_FORM_SUBMIT_PROGRESS';
const USERS_FORM_SUBMIT_SUCCESS = 'USERS_FORM_SUBMIT_SUCCESS';
const USERS_FORM_SUBMIT_FAILED = 'USERS_FORM_SUBMIT_FAILED';
const GET_USER_PROGRESS = 'GET_USER_PROGRESS';
const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
const GET_USER_FAILED = 'GET_USER_FAILED';
const USERS_CLEAR_STATE = 'USERS_CLEAR_STATE';

const actionGetUsersListProgress = () => ({
  type: GET_USERS_LIST_PROGRESS
});

const actionGetUsersListSuccess = (payload) => ({
  type: GET_USERS_LIST_SUCCESS,
  payload
});

const actionGetUsersListFailed = () => ({
  type: GET_USERS_LIST_FAILED
});

const actionUsersFormSubmitProgress = () => ({
  type: USERS_FORM_SUBMIT_PROGRESS
});

const actionUsersFormSubmitSuccess = () => ({
  type: USERS_FORM_SUBMIT_SUCCESS
});

const actionUsersFormSubmitFailed = () => ({
  type: USERS_FORM_SUBMIT_FAILED
});

const actionGetUserProgress = () => ({
  type: GET_USER_PROGRESS
});

const actionGetUserSuccess = (payload) => ({
  type: GET_USER_SUCCESS,
  payload
});

const actionGetUserFailed = () => ({
  type: GET_USER_FAILED
});

const actionUsersClearState = () => ({
  type: USERS_CLEAR_STATE
});

const convertListToTable = (data) =>
  new PaginationPageConverter(data, (doc) => ({
    id: doc.id,
    name: `${doc.firstname} ${doc.surname}`,
    email: doc.email,
    gender: typeof genders[doc.gender] !== 'undefined' ? genders[doc.gender] : UNKNOWN,
    parent: doc.parent || UNKNOWN,
    type: typeof accessTypes[doc.type] !== 'undefined' ? accessTypes[doc.type] : UNKNOWN,
    role: new UserRoles(doc.roles).getTextRole(),
    status: doc.status,
    createdAt: moment(doc.createdAt).format('D MMM YYYY, HH:mm')
  })).getConvertedData();

const getUsersList = (params) => (dispatch) => {
  dispatch(actionGetUsersListProgress());

  api
    .get('/user', { params })
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetUsersListSuccess(convertListToTable(data)));
    })
    .catch(() => dispatch(actionGetUsersListFailed()));
};

const convertCreateUserToDB = ({
  name,
  birthday,
  gender,
  parent,
  hasChildren,
  isOnboardingPassed,
  isCompletedTutorials,
  isTermsAndConditionsAccepted,
  isPrivacyPolicyAccepted,
  pushNotificationsKey,
  notifications,
  emails,
  reminders,
  status,
  email,
  password
}) => ({
  name,
  birthday: birthday instanceof Date ? birthday.toISOString() : undefined,
  gender: typeof gender === 'number' ? gender : parseInt(gender, 10),
  parent,
  hasChildren,
  isOnboardingPassed,
  isCompletedTutorials,
  isTermsAndConditionsAccepted,
  isPrivacyPolicyAccepted,
  pushNotificationsKey: pushNotificationsKey.length ? pushNotificationsKey : undefined,
  notifications,
  emails,
  reminders,
  status: status ? 1 : 0,
  email,
  password
});

const convertSetUserToDB = ({ name, birthday, gender, parent, hasChildren }) => ({
  name,
  birthday: birthday instanceof Date ? birthday.toISOString() : undefined,
  gender: typeof gender === 'number' ? gender : parseInt(gender, 10),
  parent,
  hasChildren
});

const createUser = (formData) => (dispatch) => {
  const dto = convertCreateUserToDB(formData);
  dispatch(actionUsersFormSubmitProgress());

  api
    .post('/user/create', dto)
    .then(({ status }) => {
      if (status !== CREATED) {
        throw new Error();
      }

      dispatch(actionUsersFormSubmitSuccess());
    })
    .catch(() => dispatch(actionUsersFormSubmitFailed()));
};

const convertDBUserToEdit = ({
  id,
  image,
  firstname,
  surname,
  birthday,
  gender,
  parent,
  roles,
  hasChildren,
  isOnboardingPassed,
  isCompletedTutorials,
  isTermsAndConditionsAccepted,
  isPrivacyPolicyAccepted,
  pushNotificationsKey,
  notifications,
  status,
  deleteAt,
  createdAt,
  updatedAt,
  tokens,
  email,
  type
}) => ({
  id,
  image: image || null,
  name: `${firstname}${surname ? ` ${surname}` : ''}`,
  birthday: typeof birthday !== 'undefined' ? new Date(birthday) : null,
  gender,
  parent,
  roles: convertRolesToEdit(roles),
  canEditAuthorRole: roles.indexOf(ROLE_BLOG_AUTHOR) !== -1,
  hasChildren,
  isOnboardingPassed,
  isCompletedTutorials,
  isTermsAndConditionsAccepted,
  isPrivacyPolicyAccepted,
  pushNotificationsKey: pushNotificationsKey || '',
  notifications,
  status: getStatus(status),
  deleteAt: deleteAt ? new Date(deleteAt) : null,
  createdAt: new Date(createdAt),
  updatedAt: new Date(updatedAt),
  tokens,
  email,
  type
});

const getUser = (userId) => (dispatch) => {
  dispatch(actionGetUserProgress());

  api
    .get(`/user/${userId}`)
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetUserSuccess(convertDBUserToEdit(data)));
    })
    .catch(() => dispatch(actionGetUserFailed()));
};

const updateUser = (userId, formData) =>
  new Promise((resolve, reject) => {
    const dto = convertSetUserToDB(formData);

    api
      .put(`/user/${userId}`, dto)
      .then(({ status, data }) => {
        if (status !== OK) {
          throw new Error();
        }

        let myProfileId;
        const state = store.getState();

        if (state && state.auth && state.auth.profile) {
          myProfileId = state.auth.profile.id;
        }

        if (typeof myProfileId === 'undefined') {
          throw new Error();
        }

        if (myProfileId === data.id) {
          const profile = {
            ...data,
            roleDescription: new UserRoles(data.roles).getTextRole(true)
          };
          store.dispatch(authProfileSubmitSuccess(profile));
        }

        resolve();
      })
      .catch(() => reject());
  });

const updateUserEmail = (userId, email) =>
  new Promise((resolve, reject) => {
    api
      .put(`/user/email/${userId}`, { email })
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve();
      })
      .catch(() => reject());
  });

const updateUserPassword = (userId, password) =>
  new Promise((resolve, reject) => {
    api
      .put(`/user/password/${userId}`, { password })
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve();
      })
      .catch(() => reject());
  });

const updateUserNotificationsKey = (userId, pushNotificationsKey) =>
  new Promise((resolve, reject) => {
    api
      .put(`/user/notificationsToken/${userId}`, { pushNotificationsKey })
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve();
      })
      .catch(() => reject());
  });

const updateUserFlags = (userId, flags) =>
  new Promise((resolve, reject) => {
    api
      .patch(`/user/flags/${userId}`, { ...flags })
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve();
      })
      .catch(() => reject());
  });

const updateUserNotifications = (userId, notifications) =>
  new Promise((resolve, reject) => {
    api
      .patch(`/user/notificationsSettings/${userId}`, { ...notifications })
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve();
      })
      .catch(() => reject());
  });

const updateUserRoles = (userId, newRoles) =>
  new Promise((resolve, reject) => {
    const roles = prepareToUpdateRoles(newRoles);

    api
      .put(`/user/roles/${userId}`, { roles })
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve();
      })
      .catch(() => reject());
  });

const updateUserStatus = (userId, newStatus) =>
  new Promise((resolve, reject) => {
    api
      .put(`/user/status/${userId}`, { status: newStatus.value })
      .then(({ status, data }) => {
        if (status !== OK) {
          throw new Error();
        }

        const deleteAt = data.deleteAt ? new Date(data.deleteAt) : null;
        resolve(deleteAt);
      })
      .catch(() => reject());
  });

const deleteUserTokens = (userId) =>
  new Promise((resolve, reject) => {
    api
      .delete(`/user/deleteToken/${userId}`)
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve();
      })
      .catch(() => reject());
  });

const findUserByEmail = (email, application = undefined) =>
  new Promise((resolve) => {
    api
      .get('/user/findByEmail', { params: { filter: email, application } })
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve(true);
      })
      .catch(() => resolve(false));
  });

const uploadPhoto = (userId, image) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', image);

    api
      .post(`user/photo/${userId}`, formData, { headers: { 'Content-Type': `multipart/form-data` } })
      .then(({ status, data }) => {
        if (status !== CREATED) {
          throw new Error();
        }

        store.dispatch(getUserProfile());
        resolve(data);
      })
      .catch(({ response }) => {
        let msg = ERROR_ON_THE_SERVER;

        if (response) {
          const { message, error } = response.data;
          msg = new ErrorMessage(message, error).getMessage();
        }

        reject(msg);
      });
  });

const deletePhoto = (userId) =>
  new Promise((resolve, reject) => {
    api
      .delete(`user/photo/${userId}`)
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        store.dispatch(getUserProfile());
        resolve();
      })
      .catch(({ response }) => {
        let msg = ERROR_ON_THE_SERVER;

        if (response) {
          const { message, error } = response.data;
          msg = new ErrorMessage(message, error).getMessage();
        }

        reject(msg);
      });
  });

const deleteUser = (userId, replaceableUserId = undefined) =>
  new Promise((resolve, reject) => {
    api
      .delete(`user/${userId}${replaceableUserId ? `/${replaceableUserId}` : ''}`)
      .then(({ status, data }) => {
        if (status !== OK) {
          throw new Error();
        }

        const { isDeleted } = data;
        resolve(isDeleted);
      })
      .catch(({ response }) => {
        let msg = ERROR_ON_THE_SERVER;

        if (response) {
          const { message, error } = response.data;
          msg = new ErrorMessage(message, error).getMessage();
        }

        reject(msg);
      });
  });

const getSuperAdmins = (excludeUserId = undefined) =>
  new Promise((resolve, reject) => {
    api
      .get(`user/onlySuperAdmin${excludeUserId ? `/${excludeUserId}` : ''}`)
      .then(({ status, data }) => {
        if (status !== OK) {
          throw new Error();
        }

        const admins = data.map((admin) => ({ value: admin.id, label: admin.name }));
        resolve(admins);
      })
      .catch(({ response }) => {
        let msg = ERROR_ON_THE_SERVER;

        if (response) {
          const { message, error } = response.data;
          msg = new ErrorMessage(message, error).getMessage();
        }

        reject(msg);
      });
  });

export {
  GET_USERS_LIST_PROGRESS,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAILED,
  USERS_FORM_SUBMIT_PROGRESS,
  USERS_FORM_SUBMIT_SUCCESS,
  USERS_FORM_SUBMIT_FAILED,
  GET_USER_PROGRESS,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
  USERS_CLEAR_STATE,
  actionUsersClearState,
  getUsersList,
  createUser,
  getUser,
  updateUser,
  updateUserEmail,
  updateUserPassword,
  updateUserNotificationsKey,
  updateUserFlags,
  updateUserNotifications,
  updateUserRoles,
  updateUserStatus,
  deleteUserTokens,
  findUserByEmail,
  uploadPhoto,
  deletePhoto,
  deleteUser,
  getSuperAdmins
};
