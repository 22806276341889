import moment from 'moment';
import api from '../api';
import { CREATED, NO_CONTENT, OK } from '../config/httpStatuses';
import { ROLE_BLOG_AUTHOR } from '../config/roles';
import { ERROR_ON_THE_SERVER } from '../config/textConsts';
import PaginationPageConverter from '../classes/PaginationPageConverter';
import UserRoles from '../classes/UserRoles';
import User from '../classes/User';
import ErrorMessage from '../classes/ErrorMessage';

const GET_AUTHORS_LIST_PROGRESS = 'GET_AUTHORS_LIST_PROGRESS';
const GET_AUTHORS_LIST_SUCCESS = 'GET_AUTHORS_LIST_SUCCESS';
const GET_AUTHORS_LIST_FAILED = 'GET_AUTHORS_LIST_FAILED';
const AUTHORS_FORM_SUBMIT_PROGRESS = 'AUTHORS_FORM_SUBMIT_PROGRESS';
const AUTHORS_FORM_SUBMIT_SUCCESS = 'AUTHORS_FORM_SUBMIT_SUCCESS';
const AUTHORS_FORM_SUBMIT_FAILED = 'AUTHORS_FORM_SUBMIT_FAILED';
const GET_AUTHOR_PROGRESS = 'GET_AUTHOR_PROGRESS';
const GET_AUTHOR_SUCCESS = 'GET_AUTHOR_SUCCESS';
const GET_AUTHOR_FAILED = 'GET_AUTHOR_FAILED';
const AUTHORS_CLEAR_STATE = 'AUTHORS_CLEAR_STATE';

const actionGetAuthorsListProgress = () => ({
  type: GET_AUTHORS_LIST_PROGRESS
});

const actionGetAuthorsListSuccess = (payload) => ({
  type: GET_AUTHORS_LIST_SUCCESS,
  payload
});

const actionGetAuthorsListFailed = () => ({
  type: GET_AUTHORS_LIST_FAILED
});

const actionAuthorsFormSubmitProgress = () => ({
  type: AUTHORS_FORM_SUBMIT_PROGRESS
});

const actionAuthorsFormSubmitSuccess = (payload) => ({
  type: AUTHORS_FORM_SUBMIT_SUCCESS,
  payload
});

const actionAuthorsFormSubmitFailed = (payload) => ({
  type: AUTHORS_FORM_SUBMIT_FAILED,
  payload
});

const actionGetAuthorProgress = () => ({
  type: GET_AUTHOR_PROGRESS
});

const actionGetAuthorSuccess = (payload) => ({
  type: GET_AUTHOR_SUCCESS,
  payload
});

const actionGetAuthorFailed = () => ({
  type: GET_AUTHOR_FAILED
});

const actionAuthorsClearState = () => ({
  type: AUTHORS_CLEAR_STATE
});

const convertListToTable = (data) =>
  new PaginationPageConverter(data, (doc) => ({
    id: doc.id,
    name: `${doc.user.firstname} ${doc.user.surname}`,
    articles: doc.countArticles,
    access: doc.user.roles.indexOf(ROLE_BLOG_AUTHOR) !== -1,
    slug: doc.slug,
    createdAt: moment(doc.createdAt).format('D MMM YYYY, HH:mm')
  })).getConvertedData();

const getAuthorsList = (params) => (dispatch) => {
  dispatch(actionGetAuthorsListProgress());

  api
    .get('/blog/author', { params })
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetAuthorsListSuccess(convertListToTable(data)));
    })
    .catch(() => dispatch(actionGetAuthorsListFailed()));
};

const convertSetAuthorToDB = ({
  user,
  title,
  seoTitle,
  seoDescription,
  twitter,
  instagram,
  facebook,
  linkedin,
  position,
  description,
  about,
  slug,
  isSetBlogAuthor
}) => {
  const twitterUrl = twitter || undefined;
  const instagramUrl = instagram || undefined;
  const facebookUrl = facebook || undefined;
  const linkedinUrl = linkedin || undefined;
  const socials =
    twitterUrl || instagramUrl || facebookUrl || linkedinUrl
      ? {
          twitter: twitterUrl,
          instagram: instagramUrl,
          facebook: facebookUrl,
          linkedin: linkedinUrl
        }
      : undefined;

  return {
    user: typeof user === 'string' ? user : undefined,
    title,
    seo: {
      title: seoTitle,
      description: seoDescription
    },
    socials,
    position,
    description,
    about,
    slug,
    isSetBlogAuthor: typeof isSetBlogAuthor === 'boolean' ? isSetBlogAuthor : undefined
  };
};

const createAuthor = (formData) => (dispatch) => {
  const dto = convertSetAuthorToDB(formData);
  dispatch(actionAuthorsFormSubmitProgress());

  api
    .post('/blog/author/create', dto)
    .then(({ status }) => {
      if (status !== CREATED) {
        throw new Error();
      }

      dispatch(actionAuthorsFormSubmitSuccess('The new author has been successfully created'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionAuthorsFormSubmitFailed(msg));
    });
};

const convertDBAuthorToEdit = ({
  user,
  title,
  seo,
  socials,
  position,
  description,
  about,
  slug,
  created,
  updated,
  createdAt,
  updatedAt
}) => {
  const twitter = socials && typeof socials.twitter === 'string' ? socials.twitter : '';
  const instagram = socials && typeof socials.instagram === 'string' ? socials.instagram : '';
  const facebook = socials && typeof socials.facebook === 'string' ? socials.facebook : '';
  const linkedin = socials && typeof socials.linkedin === 'string' ? socials.linkedin : '';

  return {
    user,
    title,
    seoTitle: seo.title,
    seoDescription: seo.description,
    twitter,
    instagram,
    facebook,
    linkedin,
    position,
    description,
    about,
    slug,
    isSetBlogAuthor: new UserRoles(user.roles).hasBlogAuthorAccess(),
    created,
    updated,
    createdAt,
    updatedAt
  };
};

const getAuthor = (authorId) => (dispatch) => {
  dispatch(actionGetAuthorProgress());

  api
    .get(`/blog/author/${authorId}`)
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetAuthorSuccess(convertDBAuthorToEdit(data)));
    })
    .catch(() => dispatch(actionGetAuthorFailed()));
};

const updateAuthor = (authorId, formData) => (dispatch) => {
  const dto = convertSetAuthorToDB(formData);
  dispatch(actionAuthorsFormSubmitProgress());

  api
    .put(`/blog/author/${authorId}`, dto)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionAuthorsFormSubmitSuccess('The author has been successfully edited'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionAuthorsFormSubmitFailed(msg));
    });
};

const removeAuthor = (authorId) => (dispatch) => {
  dispatch(actionAuthorsFormSubmitProgress());

  api
    .delete(`/blog/author/${authorId}`)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionAuthorsFormSubmitSuccess('The author has been successfully removed'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionAuthorsFormSubmitFailed(msg));
    });
};

const findUserByEmail = (filter) =>
  new Promise((resolve) => {
    api
      .get('/user/findByEmail', { params: { filter } })
      .then(({ status, data }) => {
        if (status !== 200) {
          throw new Error();
        }

        const users = data.map((user) => {
          const newUser = new User(user);

          return {
            user: newUser,
            value: `${newUser.name}${newUser.email ? ` (${newUser.email})` : ''}`
          };
        });

        resolve(users);
      })
      .catch(() => resolve([]));
  });

const checkAuthorIsExists = (user) =>
  new Promise((resolve, reject) => {
    api
      .get('/blog/author/findByUser', { params: { user } })
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve();
      })
      .catch(() => reject());
  });

const findAuthorBySlug = (slug, id) =>
  new Promise((resolve) => {
    api
      .get('/blog/author/findBySlug', { params: { slug, id } })
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve(false);
      })
      .catch(() => resolve(true));
  });

const grantAuthorAccessToDashboard = (authorId, isSet) =>
  new Promise((resolve, reject) => {
    api
      .put(`/blog/author/editRole/${authorId}`, { isSet })
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve(
          isSet ? 'The author has been granted access to the dashboard' : 'Access to the dashboard has been removed'
        );
      })
      .catch(({ response }) => {
        let msg = ERROR_ON_THE_SERVER;

        if (response) {
          const { message, error } = response.data;
          msg = new ErrorMessage(message, error).getMessage();
        }

        reject(msg);
      });
  });

export {
  GET_AUTHORS_LIST_PROGRESS,
  GET_AUTHORS_LIST_SUCCESS,
  GET_AUTHORS_LIST_FAILED,
  AUTHORS_FORM_SUBMIT_PROGRESS,
  AUTHORS_FORM_SUBMIT_SUCCESS,
  AUTHORS_FORM_SUBMIT_FAILED,
  GET_AUTHOR_PROGRESS,
  GET_AUTHOR_SUCCESS,
  GET_AUTHOR_FAILED,
  AUTHORS_CLEAR_STATE,
  actionAuthorsClearState,
  getAuthorsList,
  createAuthor,
  getAuthor,
  updateAuthor,
  removeAuthor,
  findUserByEmail,
  checkAuthorIsExists,
  findAuthorBySlug,
  grantAuthorAccessToDashboard
};
