class ErrorMessage {
  constructor(message, error) {
    this.message = message;
    this.error = error;
  }

  getMessage() {
    return `${this.error}: ${Array.isArray(this.message) ? this.message[0] : this.message}`;
  }
}

export default ErrorMessage;
