import {
  ROLE_ADMIN,
  ROLE_USER_ADMIN,
  ROLE_IMAGE_ADMIN,
  ROLE_BLOG_ADMIN,
  ROLE_BLOG_AUTHOR,
  ROLE_APP_ADMIN
} from '../config/roles';
import { UNKNOWN } from '../config/textConsts';

class UserRoles {
  constructor(userRoles) {
    this.userRoles = Array.isArray(userRoles) ? userRoles : [];
    this.adminRoles = [ROLE_ADMIN, ROLE_USER_ADMIN, ROLE_IMAGE_ADMIN, ROLE_BLOG_ADMIN, ROLE_APP_ADMIN];
    this.authorRole = ROLE_BLOG_AUTHOR;
    this.isSuperAdmin = false;
    this.isAdmin = false;
    this.isAuthor = false;
    this.isUser = false;

    this.calculateRoles();
  }

  calculateRoles() {
    this.isSuperAdmin = this.userRoles.indexOf(ROLE_ADMIN) !== -1;

    if (this.isSuperAdmin) {
      return;
    }

    this.isAdmin = this.adminRoles.some((role) => this.userRoles.includes(role));

    if (this.isAdmin) {
      return;
    }

    this.isAuthor = this.userRoles.indexOf(this.authorRole) !== -1;

    if (this.isAuthor) {
      return;
    }

    this.isUser = true;
  }

  getTextRole(isFullText = false) {
    let role;

    if (this.isUser) {
      role = 'User';
    } else if (this.isAdmin) {
      role = isFullText ? 'Administrator' : 'Admin';
    } else if (this.isAuthor) {
      role = 'Author';
    } else if (this.isSuperAdmin) {
      role = isFullText ? 'Super administrator' : 'Super admin';
    } else {
      role = UNKNOWN;
    }

    return role;
  }

  isCanEditUser() {
    return this.userRoles.indexOf(ROLE_ADMIN) !== -1 || this.userRoles.indexOf(ROLE_USER_ADMIN) !== -1;
  }

  hasBlogAuthorAccess() {
    return this.userRoles.indexOf(this.authorRole) !== -1;
  }
}

export default UserRoles;
