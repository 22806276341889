import { UNKNOWN } from './textConsts';

const places = [
  { value: 0, label: 'At home' },
  { value: 1, label: 'Outdoors' }
];

export default places;

export function getPlaces(placesArray) {
  const list = [];

  for (const place of placesArray) {
    const placeObject = places.find((item) => item.value === place);

    if (placeObject) {
      list.push(placeObject);
    } else {
      list.push({ value: place, label: UNKNOWN });
    }
  }

  return list;
}
