import api from '../api';
import { CREATED, NO_CONTENT, OK } from '../config/httpStatuses';
import { ERROR_ON_THE_SERVER } from '../config/textConsts';
import PaginationPageConverter from '../classes/PaginationPageConverter';
import ErrorMessage from '../classes/ErrorMessage';
import store from '../config/configureStore';
import { IMAGE_TYPE_IMAGE, IMAGE_TYPE_ORIGINAL } from '../config/imageTypes';

const SEARCH_FOLDER_ID = 'search';
const GENERAL_FOLDER_ID = 'general';

const IG_GET_INITIAL_DATA_PROGRESS = 'IG_GET_INITIAL_DATA_PROGRESS';
const IG_GET_INITIAL_DATA_SUCCESS = 'IG_GET_INITIAL_DATA_SUCCESS';
const IG_GET_INITIAL_DATA_FAILED = 'IG_GET_INITIAL_DATA_FAILED';
const IG_ADD_FOLDER_TO_LIST_PROGRESS = 'IG_ADD_FOLDER_TO_LIST_PROGRESS';
const IG_ADD_FOLDER_TO_LIST_SUCCESS = 'IG_ADD_FOLDER_TO_LIST_SUCCESS';
const IG_EDIT_ACTIVE_FOLDER_PROGRESS = 'IG_EDIT_ACTIVE_FOLDER_PROGRESS';
const IG_EDIT_ACTIVE_FOLDER_SUCCESS = 'IG_EDIT_ACTIVE_FOLDER_SUCCESS';
const IG_REMOVE_ACTIVE_FOLDER_PROGRESS = 'IG_REMOVE_ACTIVE_FOLDER_PROGRESS';
const IG_REMOVE_ACTIVE_FOLDER_SUCCESS = 'IG_REMOVE_ACTIVE_FOLDER_SUCCESS';
const IG_CHANGE_ACTIVE_FOLDER = 'IG_CHANGE_ACTIVE_FOLDER';
const IG_LOAD_IMAGES_PROGRESS = 'IG_LOAD_IMAGES_PROGRESS';
const IG_LOAD_IMAGES_SUCCESS = 'IG_LOAD_IMAGES_SUCCESS';
const IG_LOAD_IMAGES_FAILED = 'IG_LOAD_IMAGES_FAILED';
const IG_SET_SEARCH_VALUE_PROGRESS = 'IG_SET_SEARCH_VALUE_PROGRESS';
const IG_SET_SEARCH_VALUE_SUCCESS = 'IG_SET_SEARCH_VALUE_SUCCESS';
const IG_OPEN_WINDOW = 'IG_OPEN_WINDOW';
const IG_IMAGE_UPLOAD_PROGRESS = 'IG_IMAGE_UPLOAD_PROGRESS';
const IG_IMAGE_UPLOAD_SUCCESS = 'IG_IMAGE_UPLOAD_SUCCESS';
const IG_BACK_TO_FOLDERS = 'IG_BACK_TO_FOLDERS';
const IG_CHANGE_ACTIVE_IMAGE = 'IG_CHANGE_ACTIVE_IMAGE';
const IG_REMOVE_ACTIVE_IMAGE_PROGRESS = 'IG_REMOVE_ACTIVE_IMAGE_PROGRESS';
const IG_REMOVE_ACTIVE_IMAGE_SUCCESS = 'IG_REMOVE_ACTIVE_IMAGE_SUCCESS';
const IG_MOVE_ACTIVE_IMAGE_PROGRESS = 'IG_MOVE_ACTIVE_IMAGE_PROGRESS';
const IG_MOVE_ACTIVE_IMAGE_SUCCESS = 'IG_MOVE_ACTIVE_IMAGE_SUCCESS';
const IG_CHANGE_DESCRIPTION_ACTIVE_IMAGE = 'IG_CHANGE_DESCRIPTION_ACTIVE_IMAGE';
const IG_CLEAR_STATE = 'IG_CLEAR_STATE';

const actionGetInitialDataProgress = () => ({
  type: IG_GET_INITIAL_DATA_PROGRESS
});

const actionGetInitialDataSuccess = (payload) => ({
  type: IG_GET_INITIAL_DATA_SUCCESS,
  payload
});

const actionGetInitialDataFailed = () => ({
  type: IG_GET_INITIAL_DATA_FAILED
});

const actionAddFolderToListProgress = () => ({
  type: IG_ADD_FOLDER_TO_LIST_PROGRESS
});

const actionAddFolderToListSuccess = (payload) => ({
  type: IG_ADD_FOLDER_TO_LIST_SUCCESS,
  payload
});

const actionEditActiveFolderProgress = () => ({
  type: IG_EDIT_ACTIVE_FOLDER_PROGRESS
});

const actionEditActiveFolderSuccess = (payload) => ({
  type: IG_EDIT_ACTIVE_FOLDER_SUCCESS,
  payload
});

const actionRemoveActiveFolderProgress = () => ({
  type: IG_REMOVE_ACTIVE_FOLDER_PROGRESS
});

const actionRemoveActiveFolderSuccess = (payload) => ({
  type: IG_REMOVE_ACTIVE_FOLDER_SUCCESS,
  payload
});

const actionChangeActiveFolder = (payload) => ({
  type: IG_CHANGE_ACTIVE_FOLDER,
  payload
});

const actionLoadImagesProgress = () => ({
  type: IG_LOAD_IMAGES_PROGRESS
});

const actionLoadImagesSuccess = (payload) => ({
  type: IG_LOAD_IMAGES_SUCCESS,
  payload
});

const actionLoadImagesFailed = () => ({
  type: IG_LOAD_IMAGES_FAILED
});

const actionSetSearchValueProgress = () => ({
  type: IG_SET_SEARCH_VALUE_PROGRESS
});

const actionSetSearchValueSuccess = (payload) => ({
  type: IG_SET_SEARCH_VALUE_SUCCESS,
  payload
});

const actionOpenWindow = (payload) => ({
  type: IG_OPEN_WINDOW,
  payload
});

const actionUploadImagesProgress = () => ({
  type: IG_IMAGE_UPLOAD_PROGRESS
});

const actionUploadImageSuccess = (payload) => ({
  type: IG_IMAGE_UPLOAD_SUCCESS,
  payload
});

const actionBackToFolders = () => ({
  type: IG_BACK_TO_FOLDERS
});

const actionChangeActiveImage = (payload) => ({
  type: IG_CHANGE_ACTIVE_IMAGE,
  payload
});

const actionRemoveActiveImageProgress = () => ({
  type: IG_REMOVE_ACTIVE_IMAGE_PROGRESS
});

const actionRemoveActiveImageSuccess = (payload) => ({
  type: IG_REMOVE_ACTIVE_IMAGE_SUCCESS,
  payload
});

const actionMoveActiveImageProgress = () => ({
  type: IG_MOVE_ACTIVE_IMAGE_PROGRESS
});

const actionMoveActiveImageSuccess = (payload) => ({
  type: IG_MOVE_ACTIVE_IMAGE_SUCCESS,
  payload
});

const actionChangeDescriptionActiveImage = (payload) => ({
  type: IG_CHANGE_DESCRIPTION_ACTIVE_IMAGE,
  payload
});

const convertInitialData = (data) => {
  const { generalCountImages, folders } = data;
  const { list } = new PaginationPageConverter(folders, (doc) => ({
    id: doc.id,
    title: doc.title,
    description: doc.description,
    countImages: doc.countImages,
    isShow: true,
    isActions: true,
    isUpload: true
  })).getConvertedData();

  const folderList = [
    {
      id: SEARCH_FOLDER_ID,
      title: 'Search results',
      description: 'Images matching the search criteria',
      countImages: 0,
      isShow: false,
      isActions: false,
      isUpload: false
    },
    {
      id: GENERAL_FOLDER_ID,
      title: 'Shared folder',
      description: 'Here are images that are not included in other folders',
      countImages: generalCountImages,
      isShow: true,
      isActions: false,
      isUpload: true
    },
    ...list
  ];

  return {
    folders: folderList,
    currentFolder: folderList[1],
    activeFolderId: GENERAL_FOLDER_ID
  };
};

const getInitialData = () => (dispatch) => {
  dispatch(actionGetInitialDataProgress());

  api
    .get('/images/folder/initial')
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetInitialDataSuccess(convertInitialData(data)));
    })
    .catch(() => dispatch(actionGetInitialDataFailed()));
};

const convertSetFolderToDB = ({ title, description }) => ({
  title,
  description
});

const createFolder = (formData) =>
  new Promise((resolve, reject) => {
    const dto = convertSetFolderToDB(formData);

    api
      .post('/images/folder/create', dto)
      .then(({ status, data }) => {
        if (status !== CREATED) {
          throw new Error();
        }

        resolve(data);
      })
      .catch(({ response }) => {
        let msg = ERROR_ON_THE_SERVER;

        if (response) {
          const { message, error } = response.data;
          msg = new ErrorMessage(message, error).getMessage();
        }

        reject(msg);
      });
  });

const addFolderToList = (data) => (dispatch) => {
  dispatch(actionAddFolderToListProgress());
  const { folders } = store.getState().ig;

  folders.push({
    id: data.id,
    title: data.title,
    description: data.description,
    countImages: 0,
    isShow: true,
    isActions: true,
    isUpload: true
  });

  dispatch(actionAddFolderToListSuccess(folders));
};

const updateFolder = (folderId, formData) =>
  new Promise((resolve, reject) => {
    const dto = convertSetFolderToDB(formData);

    api
      .put(`/images/folder/${folderId}`, dto)
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve();
      })
      .catch(({ response }) => {
        let msg = ERROR_ON_THE_SERVER;

        if (response) {
          const { message, error } = response.data;
          msg = new ErrorMessage(message, error).getMessage();
        }

        reject(msg);
      });
  });

const editActiveFolder =
  ({ folderId, title, description }) =>
  (dispatch) => {
    dispatch(actionEditActiveFolderProgress());
    const { folders } = store.getState().ig;
    const activeFolderIndex = folders.findIndex((folder) => folder.id === folderId);

    folders[activeFolderIndex].title = title;
    folders[activeFolderIndex].description = description;

    dispatch(actionEditActiveFolderSuccess({ folders, currentFolder: folders[activeFolderIndex] }));
  };

const removeFolder = (folderId) =>
  new Promise((resolve, reject) => {
    api
      .delete(`/images/folder/${folderId}`)
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve();
      })
      .catch(({ response }) => {
        let msg = ERROR_ON_THE_SERVER;

        if (response) {
          const { message, error } = response.data;
          msg = new ErrorMessage(message, error).getMessage();
        }

        reject(msg);
      });
  });

const removeActiveFolder = (folderId) => (dispatch) => {
  dispatch(actionRemoveActiveFolderProgress());
  const { folders } = store.getState().ig;
  const activeFolderIndex = folders.findIndex((folder) => folder.id === folderId);

  folders.splice(activeFolderIndex, 1);

  dispatch(actionRemoveActiveFolderSuccess({ folders, folderId: GENERAL_FOLDER_ID, currentFolder: folders[1] }));
};

const changeActiveFolder = (folderId) => (dispatch) => {
  const { folders } = store.getState().ig;
  const currentFolder = folders.find((folder) => folder.id === folderId);

  dispatch(actionChangeActiveFolder({ folderId, currentFolder }));
};

const convertLoadImages = (data, currentImages) => {
  return {
    images: [...currentImages, ...data.docs],
    page: data.page,
    hasNextPage: data.hasNextPage
  };
};

const loadImages = () => (dispatch) => {
  dispatch(actionLoadImagesProgress());
  const { activeFolderId, images, page, search } = store.getState().ig;

  const params = {
    page: page + 1,
    limit: 100,
    column: 'createdAt',
    order: -1,
    type: [IMAGE_TYPE_IMAGE, IMAGE_TYPE_ORIGINAL],
    folder:
      !activeFolderId || activeFolderId === GENERAL_FOLDER_ID || activeFolderId === SEARCH_FOLDER_ID
        ? undefined
        : activeFolderId,
    withoutFolders: activeFolderId === SEARCH_FOLDER_ID ? 1 : undefined,
    description: activeFolderId === SEARCH_FOLDER_ID ? search : undefined
  };

  api
    .get(`images/image`, { params })
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionLoadImagesSuccess(convertLoadImages(data, images)));
    })
    .catch(() => {
      dispatch(actionLoadImagesFailed());
    });
};

const searchImages = (search) => (dispatch) => {
  dispatch(actionSetSearchValueProgress());
  const { folders } = store.getState().ig;

  setTimeout(() => {
    dispatch(actionSetSearchValueSuccess({ currentFolder: folders[0], folderId: SEARCH_FOLDER_ID, search }));
  }, 250);
};

const openWindow = (window) => (dispatch) => {
  dispatch(actionOpenWindow(window));
};

const uploadImage = (image, type, description) =>
  new Promise((resolve, reject) => {
    const { activeFolderId } = store.getState().ig;
    const formData = new FormData();

    formData.append('file', image);
    formData.append('type', type);

    if (activeFolderId !== GENERAL_FOLDER_ID && activeFolderId !== SEARCH_FOLDER_ID) {
      formData.append('folder', activeFolderId);
    }

    if (description) {
      formData.append('description', description);
    }

    api
      .post('images/image/upload', formData, { headers: { 'Content-Type': `multipart/form-data` } })
      .then(({ status }) => {
        if (status !== CREATED) {
          throw new Error();
        }

        resolve();
      })
      .catch(({ response }) => {
        let msg = ERROR_ON_THE_SERVER;

        if (response) {
          const { message, error } = response.data;
          msg = new ErrorMessage(message, error).getMessage();
        }

        reject(msg);
      });
  });

const uploadImageComplete = () => (dispatch) => {
  dispatch(actionUploadImagesProgress());

  const { folders, activeFolderId } = store.getState().ig;
  const folderIndex = folders.findIndex((folder) => folder.id === activeFolderId);

  folders[folderIndex].countImages += 1;

  dispatch(actionUploadImageSuccess(folders));
};

const backToFolders = () => (dispatch) => {
  dispatch(actionBackToFolders());
};

const changeActiveImage = (imageId, image) => (dispatch) => {
  dispatch(actionChangeActiveImage({ imageId, image }));
};

const removeImage = (imageId) =>
  new Promise((resolve, reject) => {
    api
      .delete(`images/image/${imageId}`)
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve();
      })
      .catch(({ response }) => {
        let msg = ERROR_ON_THE_SERVER;

        if (response) {
          const { message, error } = response.data;
          msg = new ErrorMessage(message, error).getMessage();
        }

        reject(msg);
      });
  });

const removeActiveImage = (imageId) => (dispatch) => {
  dispatch(actionRemoveActiveImageProgress());
  const { images, folders, activeFolderId } = store.getState().ig;
  const activeImageIndex = images.findIndex((image) => image.id === imageId);
  const activeFolderIndex = folders.findIndex((folder) => folder.id === activeFolderId);

  images.splice(activeImageIndex, 1);
  folders[activeFolderIndex].countImages -= 1;

  dispatch(actionRemoveActiveImageSuccess({ images, folders }));
};

const moveImage = (imageId, folder) =>
  new Promise((resolve, reject) => {
    api
      .put(`images/image/editFolder/${imageId}`, { folder })
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve();
      })
      .catch(({ response }) => {
        let msg = ERROR_ON_THE_SERVER;

        if (response) {
          const { message, error } = response.data;
          msg = new ErrorMessage(message, error).getMessage();
        }

        reject(msg);
      });
  });

const moveActiveImage = (imageId, folderId) => (dispatch) => {
  dispatch(actionMoveActiveImageProgress());
  const { images, folders, activeFolderId } = store.getState().ig;
  const activeImageIndex = images.findIndex((image) => image.id === imageId);
  const activeFolderIndex = folders.findIndex((folder) => folder.id === activeFolderId);
  const destinationFolderIndex = folders.findIndex((folder) => folder.id === folderId);

  images.splice(activeImageIndex, 1);
  folders[activeFolderIndex].countImages -= 1;
  folders[destinationFolderIndex].countImages += 1;

  dispatch(actionMoveActiveImageSuccess({ images, folders }));
};

const changeImageDescription = (imageId, description) =>
  new Promise((resolve, reject) => {
    api
      .put(`images/image/editDescription/${imageId}`, { description })
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve();
      })
      .catch(({ response }) => {
        let msg = ERROR_ON_THE_SERVER;

        if (response) {
          const { message, error } = response.data;
          msg = new ErrorMessage(message, error).getMessage();
        }

        reject(msg);
      });
  });

const changeDescriptionActiveImage = (imageId, description) => (dispatch) => {
  const { images } = store.getState().ig;
  const activeImageIndex = images.findIndex((image) => image.id === imageId);

  images[activeImageIndex].description = description || undefined;

  dispatch(actionChangeDescriptionActiveImage({ images, currentImage: images[activeImageIndex] }));
};

const actionIGClearState = () => ({
  type: IG_CLEAR_STATE
});

export {
  SEARCH_FOLDER_ID,
  GENERAL_FOLDER_ID,
  IG_GET_INITIAL_DATA_PROGRESS,
  IG_GET_INITIAL_DATA_SUCCESS,
  IG_GET_INITIAL_DATA_FAILED,
  IG_ADD_FOLDER_TO_LIST_PROGRESS,
  IG_ADD_FOLDER_TO_LIST_SUCCESS,
  IG_EDIT_ACTIVE_FOLDER_PROGRESS,
  IG_EDIT_ACTIVE_FOLDER_SUCCESS,
  IG_REMOVE_ACTIVE_FOLDER_PROGRESS,
  IG_REMOVE_ACTIVE_FOLDER_SUCCESS,
  IG_CHANGE_ACTIVE_FOLDER,
  IG_LOAD_IMAGES_PROGRESS,
  IG_LOAD_IMAGES_SUCCESS,
  IG_LOAD_IMAGES_FAILED,
  IG_SET_SEARCH_VALUE_PROGRESS,
  IG_SET_SEARCH_VALUE_SUCCESS,
  IG_OPEN_WINDOW,
  IG_IMAGE_UPLOAD_PROGRESS,
  IG_IMAGE_UPLOAD_SUCCESS,
  IG_BACK_TO_FOLDERS,
  IG_CHANGE_ACTIVE_IMAGE,
  IG_REMOVE_ACTIVE_IMAGE_PROGRESS,
  IG_REMOVE_ACTIVE_IMAGE_SUCCESS,
  IG_MOVE_ACTIVE_IMAGE_PROGRESS,
  IG_MOVE_ACTIVE_IMAGE_SUCCESS,
  IG_CHANGE_DESCRIPTION_ACTIVE_IMAGE,
  IG_CLEAR_STATE,
  getInitialData,
  actionIGClearState,
  createFolder,
  addFolderToList,
  updateFolder,
  editActiveFolder,
  removeFolder,
  removeActiveFolder,
  changeActiveFolder,
  loadImages,
  searchImages,
  openWindow,
  uploadImage,
  uploadImageComplete,
  backToFolders,
  changeActiveImage,
  removeImage,
  removeActiveImage,
  moveImage,
  moveActiveImage,
  changeImageDescription,
  changeDescriptionActiveImage
};
