import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';

import { HashRouter } from 'react-router-dom';
import './assets/base.scss';
import { Provider } from 'react-redux';
import ru from 'date-fns/locale/ru';
import { registerLocale } from 'react-datepicker';
import store from './config/configureStore';
import Main from './containers';
import * as serviceWorker from './serviceWorker';
import { getUserProfile } from './actions/AuthAction';

registerLocale('ru', ru);

store.dispatch(getUserProfile());

const rootElement = document.getElementById('root');

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <Component />
      </HashRouter>
    </Provider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept('./containers/index', () => {
    const NextApp = require('./containers').default;
    renderApp(NextApp);
  });
}

serviceWorker.unregister();
