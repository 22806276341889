import update from 'immutability-helper';
import {
  PROGRESS,
  SUCCESS,
  FAILED,
  AUTH_AUTHENTICATED,
  AUTH_GUEST,
  AUTH_SING_IN_PROGRESS,
  AUTH_SING_IN_SUCCESS,
  AUTH_SING_IN_FAILED,
  AUTH_TOKEN_RENEW_PROGRESS,
  AUTH_TOKEN_RENEW_SUCCESS,
  AUTH_TOKEN_RENEW_FAILED,
  AUTH_PROFILE_PROGRESS,
  AUTH_PROFILE_SUCCESS,
  AUTH_PROFILE_FAILED,
  AUTH_LOGOUT
} from '../config/types';

const getInitialState = () => {
  try {
    const tokens = JSON.parse(localStorage.getItem('tokens')) || null;

    return {
      tokens,
      profile: null,
      status: tokens ? AUTH_AUTHENTICATED : AUTH_GUEST,
      singInStatus: '', // progress/success/failed
      profileStatus: '', // progress/success/failed
      tokenRenewStatus: '' // progress/success/failed
    };
  } catch (err) {
    return {
      tokens: null,
      profile: null,
      status: AUTH_GUEST,
      singInStatus: '', // progress/success/failed
      profileStatus: '', // progress/success/failed
      tokenRenewStatus: '' // progress/success/failed
    };
  }
};

// eslint-disable-next-line default-param-last
export default function (state = getInitialState(), action) {
  switch (action.type) {
    case AUTH_SING_IN_PROGRESS: {
      return update(state, {
        singInStatus: { $set: PROGRESS }
      });
    }
    case AUTH_SING_IN_SUCCESS: {
      return update(state, {
        tokens: { $set: action.payload },
        singInStatus: { $set: SUCCESS }
      });
    }
    case AUTH_SING_IN_FAILED: {
      return update(state, {
        profile: { $set: null },
        tokens: { $set: null },
        status: { $set: AUTH_GUEST },
        singInStatus: { $set: FAILED }
      });
    }
    case AUTH_PROFILE_PROGRESS: {
      return update(state, {
        singInStatus: { $set: '' },
        profileStatus: { $set: PROGRESS }
      });
    }
    case AUTH_PROFILE_SUCCESS: {
      return update(state, {
        profile: { $set: action.payload },
        profileStatus: { $set: SUCCESS },
        status: { $set: AUTH_AUTHENTICATED }
      });
    }
    case AUTH_PROFILE_FAILED: {
      return update(state, {
        profile: { $set: null },
        tokens: { $set: null },
        status: { $set: AUTH_GUEST },
        singInStatus: { $set: '' },
        profileStatus: { $set: FAILED },
        tokenRenewStatus: { $set: '' }
      });
    }
    case AUTH_TOKEN_RENEW_PROGRESS: {
      return update(state, { tokenRenewStatus: { $set: PROGRESS } });
    }
    case AUTH_TOKEN_RENEW_SUCCESS: {
      return update(state, {
        tokens: { $set: action.payload },
        tokenRenewStatus: { $set: SUCCESS }
      });
    }
    case AUTH_TOKEN_RENEW_FAILED: {
      return update(state, {
        profile: { $set: null },
        tokens: { $set: null },
        status: { $set: AUTH_GUEST },
        singInStatus: { $set: '' },
        profileStatus: { $set: '' },
        tokenRenewStatus: { $set: FAILED }
      });
    }
    case AUTH_LOGOUT: {
      return update(state, {
        profile: { $set: null },
        tokens: { $set: null },
        status: { $set: AUTH_GUEST },
        singInStatus: { $set: '' },
        profileStatus: { $set: '' },
        tokenRenewStatus: { $set: '' }
      });
    }
    default:
      return state;
  }
}
