const PROGRESS = 'progress';
const SUCCESS = 'success';
const FAILED = 'failed';

const AUTH_AUTHENTICATED = 'authenticated';
const AUTH_GUEST = 'guest';

const AUTH_PROFILE_PROGRESS = 'AUTH_PROFILE_PROGRESS';
const AUTH_PROFILE_SUCCESS = 'AUTH_PROFILE_SUCCESS';
const AUTH_PROFILE_FAILED = 'AUTH_PROFILE_FAILED';

const AUTH_TOKEN_RENEW_PROGRESS = 'AUTH_TOKEN_RENEW_PROGRESS';
const AUTH_TOKEN_RENEW_SUCCESS = 'AUTH_TOKEN_RENEW_SUCCESS';
const AUTH_TOKEN_RENEW_FAILED = 'AUTH_TOKEN_RENEW_FAILED';

const AUTH_SING_IN_PROGRESS = 'AUTH_SING_IN_PROGRESS';
const AUTH_SING_IN_SUCCESS = 'AUTH_SING_IN_SUCCESS';
const AUTH_SING_IN_FAILED = 'AUTH_SING_IN_FAILED';
const AUTH_LOGOUT = 'AUTH_LOGOUT';

export {
  PROGRESS,
  SUCCESS,
  FAILED,
  AUTH_AUTHENTICATED,
  AUTH_GUEST,
  AUTH_PROFILE_PROGRESS,
  AUTH_PROFILE_SUCCESS,
  AUTH_PROFILE_FAILED,
  AUTH_TOKEN_RENEW_PROGRESS,
  AUTH_TOKEN_RENEW_SUCCESS,
  AUTH_TOKEN_RENEW_FAILED,
  AUTH_SING_IN_PROGRESS,
  AUTH_SING_IN_SUCCESS,
  AUTH_SING_IN_FAILED,
  AUTH_LOGOUT
};
