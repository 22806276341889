import moment from 'moment';
import api from '../api';
import { CREATED, NO_CONTENT, OK } from '../config/httpStatuses';
import { ERROR_ON_THE_SERVER } from '../config/textConsts';
import PaginationPageConverter from '../classes/PaginationPageConverter';
import ErrorMessage from '../classes/ErrorMessage';

const GET_TAGS_LIST_PROGRESS = 'GET_TAGS_LIST_PROGRESS';
const GET_TAGS_LIST_SUCCESS = 'GET_TAGS_LIST_SUCCESS';
const GET_TAGS_LIST_FAILED = 'GET_TAGS_LIST_FAILED';
const TAGS_FORM_SUBMIT_PROGRESS = 'TAGS_FORM_SUBMIT_PROGRESS';
const TAGS_FORM_SUBMIT_SUCCESS = 'TAGS_FORM_SUBMIT_SUCCESS';
const TAGS_FORM_SUBMIT_FAILED = 'TAGS_FORM_SUBMIT_FAILED';
const GET_TAG_PROGRESS = 'GET_TAG_PROGRESS';
const GET_TAG_SUCCESS = 'GET_TAG_SUCCESS';
const GET_TAG_FAILED = 'GET_TAG_FAILED';
const TAGS_CLEAR_STATE = 'TAGS_CLEAR_STATE';

const actionGetTagsListProgress = () => ({
  type: GET_TAGS_LIST_PROGRESS
});

const actionGetTagsListSuccess = (payload) => ({
  type: GET_TAGS_LIST_SUCCESS,
  payload
});

const actionGetTagsListFailed = () => ({
  type: GET_TAGS_LIST_FAILED
});

const actionTagsFormSubmitProgress = () => ({
  type: TAGS_FORM_SUBMIT_PROGRESS
});

const actionTagsFormSubmitSuccess = (payload) => ({
  type: TAGS_FORM_SUBMIT_SUCCESS,
  payload
});

const actionTagsFormSubmitFailed = (payload) => ({
  type: TAGS_FORM_SUBMIT_FAILED,
  payload
});

const actionGetTagProgress = () => ({
  type: GET_TAG_PROGRESS
});

const actionGetTagSuccess = (payload) => ({
  type: GET_TAG_SUCCESS,
  payload
});

const actionGetTagFailed = () => ({
  type: GET_TAG_FAILED
});

const actionTagsClearState = () => ({
  type: TAGS_CLEAR_STATE
});

const convertListToTable = (data) =>
  new PaginationPageConverter(data, (doc) => ({
    id: doc.id,
    title: doc.title,
    sorting: doc.sort,
    createdAt: moment(doc.createdAt).format('D MMM YYYY, HH:mm')
  })).getConvertedData();

const getTagsList = (params) => (dispatch) => {
  dispatch(actionGetTagsListProgress());

  api
    .get('/blog/tag', { params })
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetTagsListSuccess(convertListToTable(data)));
    })
    .catch(() => dispatch(actionGetTagsListFailed()));
};

const convertSetTagToDB = ({ title, seoTitle, seoDescription, slug, sort }) => ({
  title,
  seo: {
    title: seoTitle,
    description: seoDescription
  },
  slug,
  sort: parseInt(sort, 10)
});

const createTag = (formData) => (dispatch) => {
  const dto = convertSetTagToDB(formData);
  dispatch(actionTagsFormSubmitProgress());

  api
    .post('/blog/tag/create', dto)
    .then(({ status }) => {
      if (status !== CREATED) {
        throw new Error();
      }

      dispatch(actionTagsFormSubmitSuccess('The new tag has been successfully created'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionTagsFormSubmitFailed(msg));
    });
};

const convertDBTagToEdit = ({ title, seo, slug, sort, created, updated, createdAt, updatedAt }) => ({
  title,
  seoTitle: seo.title,
  seoDescription: seo.description,
  slug,
  sort: sort.toString(),
  created,
  updated,
  createdAt,
  updatedAt
});

const getTag = (tagId) => (dispatch) => {
  dispatch(actionGetTagProgress());

  api
    .get(`/blog/tag/${tagId}`)
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetTagSuccess(convertDBTagToEdit(data)));
    })
    .catch(() => dispatch(actionGetTagFailed()));
};

const updateTag = (tagId, formData) => (dispatch) => {
  const dto = convertSetTagToDB(formData);
  dispatch(actionTagsFormSubmitProgress());

  api
    .put(`/blog/tag/${tagId}`, dto)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionTagsFormSubmitSuccess('The tag has been successfully edited'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionTagsFormSubmitFailed(msg));
    });
};

const removeTag = (tagId) => (dispatch) => {
  dispatch(actionTagsFormSubmitProgress());

  api
    .delete(`/blog/tag/${tagId}`)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionTagsFormSubmitSuccess('The tag has been successfully removed'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionTagsFormSubmitFailed(msg));
    });
};

const findTagBySlug = (slug, id) =>
  new Promise((resolve) => {
    api
      .get('/blog/tag/findBySlug', { params: { slug, id } })
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve(false);
      })
      .catch(() => resolve(true));
  });

export {
  GET_TAGS_LIST_PROGRESS,
  GET_TAGS_LIST_SUCCESS,
  GET_TAGS_LIST_FAILED,
  TAGS_FORM_SUBMIT_PROGRESS,
  TAGS_FORM_SUBMIT_SUCCESS,
  TAGS_FORM_SUBMIT_FAILED,
  GET_TAG_PROGRESS,
  GET_TAG_SUCCESS,
  GET_TAG_FAILED,
  TAGS_CLEAR_STATE,
  actionTagsClearState,
  getTagsList,
  createTag,
  getTag,
  updateTag,
  removeTag,
  findTagBySlug
};
