import update from 'immutability-helper';
import { PROGRESS, SUCCESS, FAILED } from '../config/types';
import {
  GET_USERS_LIST_PROGRESS,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAILED,
  USERS_FORM_SUBMIT_PROGRESS,
  USERS_FORM_SUBMIT_SUCCESS,
  USERS_FORM_SUBMIT_FAILED,
  GET_USER_PROGRESS,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
  USERS_CLEAR_STATE
} from '../actions/UsersAction';

const initialState = {
  loadListStatus: '',
  loadActiveStatus: '',
  submitStatus: '',
  list: [],
  pagination: {},
  active: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS_LIST_PROGRESS: {
      return update(state, {
        list: { $set: [] },
        pagination: { $set: {} },
        loadListStatus: { $set: PROGRESS }
      });
    }
    case GET_USERS_LIST_SUCCESS: {
      return update(state, {
        list: { $set: action.payload.list },
        pagination: { $set: action.payload.pagination },
        loadListStatus: { $set: SUCCESS }
      });
    }
    case GET_USERS_LIST_FAILED: {
      return update(state, {
        list: { $set: [] },
        pagination: { $set: {} },
        loadListStatus: { $set: FAILED }
      });
    }
    case USERS_FORM_SUBMIT_PROGRESS: {
      return update(state, { submitStatus: { $set: PROGRESS } });
    }
    case USERS_FORM_SUBMIT_SUCCESS: {
      return update(state, {
        submitStatus: { $set: SUCCESS }
      });
    }
    case USERS_FORM_SUBMIT_FAILED: {
      return update(state, { submitStatus: { $set: FAILED } });
    }
    case GET_USER_PROGRESS: {
      return update(state, {
        active: { $set: {} },
        loadActiveStatus: { $set: PROGRESS }
      });
    }
    case GET_USER_SUCCESS: {
      return update(state, {
        active: { $set: action.payload },
        loadActiveStatus: { $set: SUCCESS }
      });
    }
    case GET_USER_FAILED: {
      return update(state, {
        active: { $set: {} },
        loadActiveStatus: { $set: FAILED }
      });
    }
    case USERS_CLEAR_STATE: {
      return update(state, {
        loadListStatus: { $set: '' },
        loadActiveStatus: { $set: '' },
        submitStatus: { $set: '' },
        list: { $set: [] },
        pagination: { $set: {} },
        active: { $set: {} }
      });
    }
    default:
      return state;
  }
}
