import moment from 'moment';
import api from '../api';
import { CREATED, NO_CONTENT, OK } from '../config/httpStatuses';
import { ERROR_ON_THE_SERVER } from '../config/textConsts';
import PaginationPageConverter from '../classes/PaginationPageConverter';
import ErrorMessage from '../classes/ErrorMessage';
import RandomString from '../classes/RandomString';

const GET_PAGES_LIST_PROGRESS = 'GET_PAGES_LIST_PROGRESS';
const GET_PAGES_LIST_SUCCESS = 'GET_PAGES_LIST_SUCCESS';
const GET_PAGES_LIST_FAILED = 'GET_PAGES_LIST_FAILED';
const PAGES_FORM_SUBMIT_PROGRESS = 'PAGES_FORM_SUBMIT_PROGRESS';
const PAGES_FORM_SUBMIT_SUCCESS = 'PAGES_FORM_SUBMIT_SUCCESS';
const PAGES_FORM_SUBMIT_FAILED = 'PAGES_FORM_SUBMIT_FAILED';
const GET_PAGE_PROGRESS = 'GET_PAGE_PROGRESS';
const GET_PAGE_SUCCESS = 'GET_PAGE_SUCCESS';
const GET_PAGE_FAILED = 'GET_PAGE_FAILED';
const PAGES_CLEAR_STATE = 'PAGES_CLEAR_STATE';

const actionGetPagesListProgress = () => ({
  type: GET_PAGES_LIST_PROGRESS
});

const actionGetPagesListSuccess = (payload) => ({
  type: GET_PAGES_LIST_SUCCESS,
  payload
});

const actionGetPagesListFailed = () => ({
  type: GET_PAGES_LIST_FAILED
});

const actionPagesFormSubmitProgress = () => ({
  type: PAGES_FORM_SUBMIT_PROGRESS
});

const actionPagesFormSubmitSuccess = (payload) => ({
  type: PAGES_FORM_SUBMIT_SUCCESS,
  payload
});

const actionPagesFormSubmitFailed = (payload) => ({
  type: PAGES_FORM_SUBMIT_FAILED,
  payload
});

const actionGetPageProgress = () => ({
  type: GET_PAGE_PROGRESS
});

const actionGetPageSuccess = (payload) => ({
  type: GET_PAGE_SUCCESS,
  payload
});

const actionGetPageFailed = () => ({
  type: GET_PAGE_FAILED
});

const actionPagesClearState = () => ({
  type: PAGES_CLEAR_STATE
});

const convertListToTable = (data) =>
  new PaginationPageConverter(data, (doc) => ({
    id: doc.id,
    title: doc.title,
    slug: doc.slug,
    createdAt: moment(doc.createdAt).format('D MMM YYYY, HH:mm')
  })).getConvertedData();

const getPagesList = (params) => (dispatch) => {
  dispatch(actionGetPagesListProgress());

  api
    .get('/blog/page', { params })
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetPagesListSuccess(convertListToTable(data)));
    })
    .catch(() => dispatch(actionGetPagesListFailed()));
};

const convertSetPageToDB = ({
  title,
  seoTitle,
  seoDescription,
  description,
  content,
  anchors,
  vars,
  slug,
  template,
  isPreview
}) => ({
  title,
  seo: {
    title: seoTitle,
    description: seoDescription
  },
  description,
  content,
  anchors,
  vars,
  slug: !isPreview ? slug : new RandomString().getString(),
  template,
  isPreview
});

const createPage = (formData) => (dispatch) => {
  const dto = convertSetPageToDB(formData);
  dispatch(actionPagesFormSubmitProgress());

  api
    .post('/blog/page/create', dto)
    .then(({ status }) => {
      if (status !== CREATED) {
        throw new Error();
      }

      dispatch(actionPagesFormSubmitSuccess('The new page has been successfully created'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionPagesFormSubmitFailed(msg));
    });
};

const convertDBPageToEdit = ({
  title,
  seo,
  description,
  content,
  anchors,
  vars,
  slug,
  template,
  isRemovable,
  created,
  updated,
  createdAt,
  updatedAt
}) => ({
  title,
  seoTitle: seo.title,
  seoDescription: seo.description,
  description,
  content,
  anchors,
  vars,
  slug,
  template,
  isRemovable,
  created,
  updated,
  createdAt,
  updatedAt
});

const getPage = (pageId) => (dispatch) => {
  dispatch(actionGetPageProgress());

  api
    .get(`/blog/page/${pageId}`)
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetPageSuccess(convertDBPageToEdit(data)));
    })
    .catch(() => dispatch(actionGetPageFailed()));
};

const updatePage = (pageId, formData) => (dispatch) => {
  const dto = convertSetPageToDB(formData);
  dispatch(actionPagesFormSubmitProgress());

  api
    .put(`/blog/page/${pageId}`, dto)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionPagesFormSubmitSuccess('The page has been successfully edited'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionPagesFormSubmitFailed(msg));
    });
};

const previewPage = (formData, pageId) =>
  new Promise((resolve, reject) => {
    const createPreview = (dto) =>
      new Promise((res, rej) => {
        api
          .post('/blog/page/create', dto)
          .then(({ status, data }) => {
            if (status !== CREATED) {
              throw new Error();
            }

            res(data.id);
          })
          .catch((error) => rej(error));
      });

    const updatePreview = (dto) =>
      new Promise((res, rej) => {
        api
          .put(`/blog/page/${pageId}`, dto)
          .then(({ status }) => {
            if (status !== NO_CONTENT) {
              throw new Error();
            }

            res(pageId);
          })
          .catch((error) => rej(error));
      });

    Promise.resolve(convertSetPageToDB(formData))
      .then((dto) => (!pageId ? createPreview(dto) : updatePreview(dto)))
      .then((previewId) => resolve(previewId))
      .catch(({ response }) => {
        let msg = ERROR_ON_THE_SERVER;

        if (response) {
          const { message, error } = response.data;
          msg = new ErrorMessage(message, error).getMessage();
        }

        reject(msg);
      });
  });

const removePage = (pageId) => (dispatch) => {
  dispatch(actionPagesFormSubmitProgress());

  api
    .delete(`/blog/page/${pageId}`)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionPagesFormSubmitSuccess('The page has been successfully removed'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionPagesFormSubmitFailed(msg));
    });
};

const findPageBySlug = (slug, id) =>
  new Promise((resolve) => {
    api
      .get('/blog/page/findBySlug', { params: { slug, id } })
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve(false);
      })
      .catch(() => resolve(true));
  });

export {
  GET_PAGES_LIST_PROGRESS,
  GET_PAGES_LIST_SUCCESS,
  GET_PAGES_LIST_FAILED,
  PAGES_FORM_SUBMIT_PROGRESS,
  PAGES_FORM_SUBMIT_SUCCESS,
  PAGES_FORM_SUBMIT_FAILED,
  GET_PAGE_PROGRESS,
  GET_PAGE_SUCCESS,
  GET_PAGE_FAILED,
  PAGES_CLEAR_STATE,
  actionPagesClearState,
  getPagesList,
  createPage,
  getPage,
  updatePage,
  previewPage,
  removePage,
  findPageBySlug
};
