import api from '../api';
import { NO_CONTENT, OK } from '../config/httpStatuses';
import { ERROR_ON_THE_SERVER } from '../config/textConsts';
import ErrorMessage from '../classes/ErrorMessage';

const SETTINGS_FORM_SUBMIT_PROGRESS = 'SETTINGS_FORM_SUBMIT_PROGRESS';
const SETTINGS_FORM_SUBMIT_SUCCESS = 'SETTINGS_FORM_SUBMIT_SUCCESS';
const SETTINGS_FORM_SUBMIT_FAILED = 'SETTINGS_FORM_SUBMIT_FAILED';
const GET_SETTINGS_PROGRESS = 'GET_SETTINGS_PROGRESS';
const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
const GET_SETTINGS_FAILED = 'GET_SETTINGS_FAILED';
const SETTINGS_CLEAR_STATE = 'SETTINGS_CLEAR_STATE';

const actionSettingsFormSubmitProgress = () => ({
  type: SETTINGS_FORM_SUBMIT_PROGRESS
});

const actionSettingsFormSubmitSuccess = (payload) => ({
  type: SETTINGS_FORM_SUBMIT_SUCCESS,
  payload
});

const actionSettingsFormSubmitFailed = (payload) => ({
  type: SETTINGS_FORM_SUBMIT_FAILED,
  payload
});

const actionGetSettingsProgress = () => ({
  type: GET_SETTINGS_PROGRESS
});

const actionGetSettingsSuccess = (payload) => ({
  type: GET_SETTINGS_SUCCESS,
  payload
});

const actionGetSettingsFailed = () => ({
  type: GET_SETTINGS_FAILED
});

const actionSettingsClearState = () => ({
  type: SETTINGS_CLEAR_STATE
});

const convertDBSettingsToEdit = ({
  isEngineeringWorks,
  socials,
  logoText,
  mainPage,
  searchPlaceholder,
  subscription,
  footer,
  innerHtml,
  linksToStores,
  contacts,
  robots,
  microMarking,
  updated,
  updatedAt
}) => {
  const { title, description, h1 } = mainPage;
  const { text, placeholder } = subscription;
  const { text: footerText, copyright } = footer;
  const { apple, google } = linksToStores;
  const { email, phone, address } = contacts;
  const convertToEmptyString = (value) => (typeof value === 'undefined' ? '' : value);

  return {
    isEngineeringWorks,
    socialsTwitter: socials ? convertToEmptyString(socials.twitter) : '',
    socialsInstagram: socials ? convertToEmptyString(socials.instagram) : '',
    socialsFacebook: socials ? convertToEmptyString(socials.facebook) : '',
    socialsTiktok: socials ? convertToEmptyString(socials.tiktok) : '',
    socialsPinterest: socials ? convertToEmptyString(socials.pinterest) : '',
    socialsYoutube: socials ? convertToEmptyString(socials.youtube) : '',
    socialsLinkedin: socials ? convertToEmptyString(socials.linkedin) : '',
    logoText,
    mainPageTitle: title,
    mainPageDescription: description,
    mainPageH1: h1,
    searchPlaceholder,
    subscriptionText: text,
    subscriptionPlaceholder: placeholder,
    footerText,
    footerCopyright: copyright,
    innerHtmlHeader: innerHtml ? convertToEmptyString(innerHtml.header) : '',
    innerHtmlBody: innerHtml ? convertToEmptyString(innerHtml.body) : '',
    innerHtmlFooter: innerHtml ? convertToEmptyString(innerHtml.footer) : '',
    linksToStoresApple: apple,
    linksToStoresGoogle: google,
    contactsEmail: email,
    contactsPhone: convertToEmptyString(phone),
    contactsAddress: convertToEmptyString(address),
    robots: convertToEmptyString(robots),
    microMarking: convertToEmptyString(microMarking),
    updated,
    updatedAt
  };
};

const getSettings = () => (dispatch) => {
  dispatch(actionGetSettingsProgress());

  api
    .get('/blog/setting')
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetSettingsSuccess(convertDBSettingsToEdit(data)));
    })
    .catch(() => dispatch(actionGetSettingsFailed()));
};

const convertSetSettingsToDB = ({
  isEngineeringWorks,
  socialsTwitter,
  socialsInstagram,
  socialsFacebook,
  socialsTiktok,
  socialsPinterest,
  socialsYoutube,
  socialsLinkedin,
  logoText,
  mainPageTitle,
  mainPageDescription,
  mainPageH1,
  searchPlaceholder,
  subscriptionText,
  subscriptionPlaceholder,
  footerText,
  footerCopyright,
  innerHtmlHeader,
  innerHtmlBody,
  innerHtmlFooter,
  linksToStoresApple,
  linksToStoresGoogle,
  contactsEmail,
  contactsPhone,
  contactsAddress,
  robots,
  microMarking
}) => {
  const convertToUndefined = (value) => (typeof value === 'string' && value.length ? value : undefined);
  let socials;
  let innerHtml;

  if (
    socialsTwitter ||
    socialsInstagram ||
    socialsFacebook ||
    socialsTiktok ||
    socialsPinterest ||
    socialsYoutube ||
    socialsLinkedin
  ) {
    socials = {
      twitter: convertToUndefined(socialsTwitter),
      instagram: convertToUndefined(socialsInstagram),
      facebook: convertToUndefined(socialsFacebook),
      tiktok: convertToUndefined(socialsTiktok),
      pinterest: convertToUndefined(socialsPinterest),
      youtube: convertToUndefined(socialsYoutube),
      linkedin: convertToUndefined(socialsLinkedin)
    };
  }

  if (innerHtmlHeader || innerHtmlBody || innerHtmlFooter) {
    innerHtml = {
      header: convertToUndefined(innerHtmlHeader),
      body: convertToUndefined(innerHtmlBody),
      footer: convertToUndefined(innerHtmlFooter)
    };
  }

  const mainPage = {
    title: mainPageTitle,
    description: mainPageDescription,
    h1: mainPageH1
  };

  const subscription = {
    text: subscriptionText,
    placeholder: subscriptionPlaceholder
  };

  const footer = {
    text: footerText,
    copyright: footerCopyright
  };

  const linksToStores = {
    apple: linksToStoresApple,
    google: linksToStoresGoogle
  };

  const contacts = {
    email: contactsEmail,
    phone: convertToUndefined(contactsPhone),
    address: convertToUndefined(contactsAddress)
  };

  return {
    isEngineeringWorks,
    socials,
    logoText,
    mainPage,
    searchPlaceholder,
    subscription,
    footer,
    innerHtml,
    linksToStores,
    contacts,
    robots: convertToUndefined(robots),
    microMarking: convertToUndefined(microMarking)
  };
};

const updateSettings = (formData) => (dispatch) => {
  const dto = convertSetSettingsToDB(formData);
  dispatch(actionSettingsFormSubmitProgress());

  api
    .put('/blog/setting', dto)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionSettingsFormSubmitSuccess('The settings has been successfully edited'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionSettingsFormSubmitFailed(msg));
    });
};

export {
  SETTINGS_FORM_SUBMIT_PROGRESS,
  SETTINGS_FORM_SUBMIT_SUCCESS,
  SETTINGS_FORM_SUBMIT_FAILED,
  GET_SETTINGS_PROGRESS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILED,
  SETTINGS_CLEAR_STATE,
  actionSettingsClearState,
  getSettings,
  updateSettings
};
