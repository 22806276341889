import moment from 'moment';
import api from '../api';
import { CREATED, NO_CONTENT, OK } from '../config/httpStatuses';
import { ERROR_ON_THE_SERVER } from '../config/textConsts';
import PaginationPageConverter from '../classes/PaginationPageConverter';
import ErrorMessage from '../classes/ErrorMessage';
import { getPlaces } from '../config/appPlaces';
import { getAges } from '../config/appAges';

const GET_APP_PASTIMES_LIST_PROGRESS = 'GET_APP_PASTIMES_LIST_PROGRESS';
const GET_APP_PASTIMES_LIST_SUCCESS = 'GET_APP_PASTIMES_LIST_SUCCESS';
const GET_APP_PASTIMES_LIST_FAILED = 'GET_APP_PASTIMES_LIST_FAILED';
const APP_PASTIMES_FORM_SUBMIT_PROGRESS = 'APP_PASTIMES_FORM_SUBMIT_PROGRESS';
const APP_PASTIMES_FORM_SUBMIT_SUCCESS = 'APP_PASTIMES_FORM_SUBMIT_SUCCESS';
const APP_PASTIMES_FORM_SUBMIT_FAILED = 'APP_PASTIMES_FORM_SUBMIT_FAILED';
const GET_APP_PASTIME_PROGRESS = 'GET_APP_PASTIME_PROGRESS';
const GET_APP_PASTIME_SUCCESS = 'GET_APP_PASTIME_SUCCESS';
const GET_APP_PASTIME_FAILED = 'GET_APP_PASTIME_FAILED';
const GET_APP_PASTIME_AUXILIARY_DATA_PROGRESS = 'GET_APP_PASTIME_AUXILIARY_DATA_PROGRESS';
const GET_APP_PASTIME_AUXILIARY_DATA_SUCCESS = 'GET_APP_PASTIME_AUXILIARY_DATA_SUCCESS';
const GET_APP_PASTIME_AUXILIARY_DATA_FAILED = 'GET_APP_PASTIME_AUXILIARY_DATA_FAILED';
const APP_PASTIMES_CLEAR_STATE = 'APP_PASTIMES_CLEAR_STATE';

const actionGetAppPastimesListProgress = () => ({
  type: GET_APP_PASTIMES_LIST_PROGRESS
});

const actionGetAppPastimesListSuccess = (payload) => ({
  type: GET_APP_PASTIMES_LIST_SUCCESS,
  payload
});

const actionGetAppPastimesListFailed = () => ({
  type: GET_APP_PASTIMES_LIST_FAILED
});

const actionAppPastimesFormSubmitProgress = () => ({
  type: APP_PASTIMES_FORM_SUBMIT_PROGRESS
});

const actionAppPastimesFormSubmitSuccess = (payload) => ({
  type: APP_PASTIMES_FORM_SUBMIT_SUCCESS,
  payload
});

const actionAppPastimesFormSubmitFailed = (payload) => ({
  type: APP_PASTIMES_FORM_SUBMIT_FAILED,
  payload
});

const actionGetAppPastimeProgress = () => ({
  type: GET_APP_PASTIME_PROGRESS
});

const actionGetAppPastimeSuccess = (payload) => ({
  type: GET_APP_PASTIME_SUCCESS,
  payload
});

const actionGetAppPastimeFailed = () => ({
  type: GET_APP_PASTIME_FAILED
});

const actionGetAppPastimeAuxiliaryDataProgress = () => ({
  type: GET_APP_PASTIME_AUXILIARY_DATA_PROGRESS
});

const actionGetAppPastimeAuxiliaryDataSuccess = (payload) => ({
  type: GET_APP_PASTIME_AUXILIARY_DATA_SUCCESS,
  payload
});

const actionGetAppPastimeAuxiliaryDataFailed = () => ({
  type: GET_APP_PASTIME_AUXILIARY_DATA_FAILED
});

const actionAppPastimesClearState = () => ({
  type: APP_PASTIMES_CLEAR_STATE
});

const convertListToTable = (data) =>
  new PaginationPageConverter(data, (doc) => ({
    id: doc.id,
    title: doc.title,
    places: getPlaces(doc.places),
    categories: doc.categories,
    time: doc.time,
    age: doc.age,
    materials: doc.materials,
    createdAt: moment(doc.createdAt).format('D MMM YYYY, HH:mm')
  })).getConvertedData();

const getPastimesList = (params) => (dispatch) => {
  dispatch(actionGetAppPastimesListProgress());

  api
    .get('/app/pastime', { params })
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetAppPastimesListSuccess(convertListToTable(data)));
    })
    .catch(() => dispatch(actionGetAppPastimesListFailed()));
};

const convertSetPastimeToDB = ({
  image,
  title,
  content,
  categories,
  places,
  minutes,
  time,
  ages,
  age,
  materials,
  video
}) => ({
  image: image || undefined,
  title,
  content: content || '',
  categories: categories.length ? categories.map((category) => category.value) : undefined,
  places: places.map((place) => place.value),
  minutes: parseInt(minutes, 10),
  time,
  ages: ages.map((item) => item.value),
  age,
  materials,
  video: video || undefined
});

const createPastime = (formData) => (dispatch) => {
  const dto = convertSetPastimeToDB(formData);
  dispatch(actionAppPastimesFormSubmitProgress());

  api
    .post('/app/pastime/create', dto)
    .then(({ status }) => {
      if (status !== CREATED) {
        throw new Error();
      }

      dispatch(actionAppPastimesFormSubmitSuccess('The new pastime has been successfully created'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionAppPastimesFormSubmitFailed(msg));
    });
};

const convertDBPastimeToEdit = ({
  image,
  title,
  content,
  categories,
  places,
  minutes,
  time,
  ages,
  age,
  materials,
  video,
  created,
  updated,
  createdAt,
  updatedAt
}) => ({
  image: image || null,
  title,
  content,
  categories: categories ? categories.map((category) => ({ value: category.id, label: category.title })) : [],
  places: getPlaces(places),
  minutes: minutes.toString(),
  time,
  ages: getAges(ages),
  age,
  materials,
  video: video || '',
  created,
  updated,
  createdAt,
  updatedAt
});

const getPastime = (pastimeId) => (dispatch) => {
  dispatch(actionGetAppPastimeProgress());

  api
    .get(`/app/pastime/${pastimeId}`)
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetAppPastimeSuccess(convertDBPastimeToEdit(data)));
    })
    .catch(() => dispatch(actionGetAppPastimeFailed()));
};

const updatePastime = (pastimeId, formData) => (dispatch) => {
  const dto = convertSetPastimeToDB(formData);
  dispatch(actionAppPastimesFormSubmitProgress());

  api
    .put(`/app/pastime/${pastimeId}`, dto)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionAppPastimesFormSubmitSuccess('The pastime has been successfully edited'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionAppPastimesFormSubmitFailed(msg));
    });
};

const removePastime = (pastimeId) => (dispatch) => {
  dispatch(actionAppPastimesFormSubmitProgress());

  api
    .delete(`/app/pastime/${pastimeId}`)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionAppPastimesFormSubmitSuccess('The pastime has been successfully removed'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionAppPastimesFormSubmitFailed(msg));
    });
};

const getAuxiliaryData = () => (dispatch) => {
  dispatch(actionGetAppPastimeAuxiliaryDataProgress());

  api
    .get('/app/pastime/auxiliaryData')
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(
        actionGetAppPastimeAuxiliaryDataSuccess({
          categories: data.categories.map((category) => ({ value: category.id, label: category.title }))
        })
      );
    })
    .catch(() => dispatch(actionGetAppPastimeAuxiliaryDataFailed()));
};

export {
  GET_APP_PASTIMES_LIST_PROGRESS,
  GET_APP_PASTIMES_LIST_SUCCESS,
  GET_APP_PASTIMES_LIST_FAILED,
  APP_PASTIMES_FORM_SUBMIT_PROGRESS,
  APP_PASTIMES_FORM_SUBMIT_SUCCESS,
  APP_PASTIMES_FORM_SUBMIT_FAILED,
  GET_APP_PASTIME_PROGRESS,
  GET_APP_PASTIME_SUCCESS,
  GET_APP_PASTIME_FAILED,
  GET_APP_PASTIME_AUXILIARY_DATA_PROGRESS,
  GET_APP_PASTIME_AUXILIARY_DATA_SUCCESS,
  GET_APP_PASTIME_AUXILIARY_DATA_FAILED,
  APP_PASTIMES_CLEAR_STATE,
  actionAppPastimesClearState,
  getPastimesList,
  createPastime,
  getPastime,
  updatePastime,
  removePastime,
  getAuxiliaryData
};
