export const ROLE_ADMIN = 'Admin';
export const ROLE_USER_ADMIN = 'UserAdmin';
export const ROLE_IMAGE_ADMIN = 'ImageAdmin';
export const ROLE_BLOG_ADMIN = 'BlogAdmin';
export const ROLE_BLOG_AUTHOR = 'BlogAuthor';
export const ROLE_BLOG_USER = 'BlogUser';
export const ROLE_BLOG_COMMENTATOR = 'BlogCommentator';
export const ROLE_APP_ADMIN = 'AppAdmin';
export const ROLE_APP_USER = 'AppUser';
export const ROLE_APP_COMMENTATOR = 'AppCommentator';

export default [
  ROLE_ADMIN,
  ROLE_USER_ADMIN,
  ROLE_IMAGE_ADMIN,
  ROLE_BLOG_ADMIN,
  ROLE_BLOG_AUTHOR,
  ROLE_BLOG_USER,
  ROLE_BLOG_COMMENTATOR,
  ROLE_APP_ADMIN,
  ROLE_APP_USER,
  ROLE_APP_COMMENTATOR
];

export function prepareToUpdateRoles(newRoles) {
  const roleKeys = Object.keys(newRoles);
  const selectedRoles = [];
  let roles = [];

  for (let i = 0; i < roleKeys.length; i += 1) {
    if (newRoles[roleKeys[i]]) {
      selectedRoles.push(roleKeys[i]);
    }
  }

  if (selectedRoles.indexOf(ROLE_ADMIN) !== -1) {
    roles = selectedRoles.filter((role) => {
      if (
        role === ROLE_USER_ADMIN ||
        role === ROLE_IMAGE_ADMIN ||
        role === ROLE_BLOG_ADMIN ||
        role === ROLE_APP_ADMIN
      ) {
        return false;
      }

      return true;
    });
  } else {
    roles = selectedRoles;
  }

  return roles;
}

export function convertRolesToEdit(currentRoles) {
  const roles = currentRoles;

  if (currentRoles.indexOf(ROLE_ADMIN) !== -1) {
    roles.push(ROLE_USER_ADMIN);
    roles.push(ROLE_IMAGE_ADMIN);
    roles.push(ROLE_BLOG_ADMIN);
    roles.push(ROLE_APP_ADMIN);
  }

  return roles;
}
