import { Route, Redirect } from 'react-router-dom';
import React, { Suspense, lazy, Fragment } from 'react';
import Loader from 'react-loaders';
import { ToastContainer } from 'react-toastify';

const Account = lazy(() => import('../../containers/Account'));
const Analytics = lazy(() => import('../../containers/Analytics'));
const Users = lazy(() => import('../../containers/Users'));
const Blog = lazy(() => import('../../containers/Blog'));
const App = lazy(() => import('../../containers/App'));

const AppMain = () => {
  return (
    <Fragment>
      {/* Analytics */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
              <h6 className="mt-5">Wait</h6>
            </div>
          </div>
        }>
        <Route path="/dashboard" component={Analytics} />
      </Suspense>

      {/* Users */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
              <h6 className="mt-5">Wait</h6>
            </div>
          </div>
        }>
        <Route path="/users" component={Users} />
      </Suspense>

      {/* Blog */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
              <h6 className="mt-5">Wait</h6>
            </div>
          </div>
        }>
        <Route path="/blog" component={Blog} />
      </Suspense>

      {/* App */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
              <h6 className="mt-5">Wait</h6>
            </div>
          </div>
        }>
        <Route path="/app" component={App} />
      </Suspense>

      {/* Account */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party" />
              </div>
              <h6 className="mt-3">Wait</h6>
            </div>
          </div>
        }>
        <Route path="/account" component={Account} />
      </Suspense>

      <Route exact path="/" render={() => <Redirect to="/dashboard" />} />

      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
