import moment from 'moment';
import api from '../api';
import { CREATED, NO_CONTENT, OK } from '../config/httpStatuses';
import { ERROR_ON_THE_SERVER } from '../config/textConsts';
import PaginationPageConverter from '../classes/PaginationPageConverter';
import ErrorMessage from '../classes/ErrorMessage';
import RandomString from '../classes/RandomString';
import User from '../classes/User';

const GET_ARTICLES_LIST_PROGRESS = 'GET_ARTICLES_LIST_PROGRESS';
const GET_ARTICLES_LIST_SUCCESS = 'GET_ARTICLES_LIST_SUCCESS';
const GET_ARTICLES_LIST_FAILED = 'GET_ARTICLES_LIST_FAILED';
const ARTICLES_FORM_SUBMIT_PROGRESS = 'ARTICLES_FORM_SUBMIT_PROGRESS';
const ARTICLES_FORM_SUBMIT_SUCCESS = 'ARTICLES_FORM_SUBMIT_SUCCESS';
const ARTICLES_FORM_SUBMIT_FAILED = 'ARTICLES_FORM_SUBMIT_FAILED';
const GET_ARTICLE_PROGRESS = 'GET_ARTICLE_PROGRESS';
const GET_ARTICLE_SUCCESS = 'GET_ARTICLE_SUCCESS';
const GET_ARTICLE_FAILED = 'GET_ARTICLE_FAILED';
const GET_ARTICLE_AUXILIARY_DATA_PROGRESS = 'GET_ARTICLE_AUXILIARY_DATA_PROGRESS';
const GET_ARTICLE_AUXILIARY_DATA_SUCCESS = 'GET_ARTICLE_AUXILIARY_DATA_SUCCESS';
const GET_ARTICLE_AUXILIARY_DATA_FAILED = 'GET_ARTICLE_AUXILIARY_DATA_FAILED';
const ARTICLES_CLEAR_STATE = 'ARTICLES_CLEAR_STATE';

const actionGetArticlesListProgress = () => ({
  type: GET_ARTICLES_LIST_PROGRESS
});

const actionGetArticlesListSuccess = (payload) => ({
  type: GET_ARTICLES_LIST_SUCCESS,
  payload
});

const actionGetArticlesListFailed = () => ({
  type: GET_ARTICLES_LIST_FAILED
});

const actionArticlesFormSubmitProgress = () => ({
  type: ARTICLES_FORM_SUBMIT_PROGRESS
});

const actionArticlesFormSubmitSuccess = (payload) => ({
  type: ARTICLES_FORM_SUBMIT_SUCCESS,
  payload
});

const actionArticlesFormSubmitFailed = (payload) => ({
  type: ARTICLES_FORM_SUBMIT_FAILED,
  payload
});

const actionGetArticleProgress = () => ({
  type: GET_ARTICLE_PROGRESS
});

const actionGetArticleSuccess = (payload) => ({
  type: GET_ARTICLE_SUCCESS,
  payload
});

const actionGetArticleFailed = () => ({
  type: GET_ARTICLE_FAILED
});

const actionGetArticleAuxiliaryDataProgress = () => ({
  type: GET_ARTICLE_AUXILIARY_DATA_PROGRESS
});

const actionGetArticleAuxiliaryDataSuccess = (payload) => ({
  type: GET_ARTICLE_AUXILIARY_DATA_SUCCESS,
  payload
});

const actionGetArticleAuxiliaryDataFailed = () => ({
  type: GET_ARTICLE_AUXILIARY_DATA_FAILED
});

const actionArticlesClearState = () => ({
  type: ARTICLES_CLEAR_STATE
});

const convertListToTable = (data) =>
  new PaginationPageConverter(data, (doc) => ({
    id: doc.id,
    title: doc.title,
    slug: doc.slug,
    category: doc.category.title,
    author: new User(doc.author.user).name,
    tags: doc.tags,
    createdAt: moment(doc.createdAt).format('D MMM YYYY, HH:mm')
  })).getConvertedData();

const getArticlesList = (params) => (dispatch) => {
  dispatch(actionGetArticlesListProgress());

  api
    .get('/blog/article', { params })
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetArticlesListSuccess(convertListToTable(data)));
    })
    .catch(() => dispatch(actionGetArticlesListFailed()));
};

const convertSetArticleToDB = ({
  image,
  title,
  seoTitle,
  seoDescription,
  header,
  content,
  read,
  anchors,
  slug,
  category,
  tags,
  author,
  reviewers,
  isPreview
}) => ({
  image: image || undefined,
  title,
  seo: {
    title: seoTitle,
    description: seoDescription
  },
  header,
  content,
  read,
  anchors,
  slug: !isPreview ? slug : new RandomString().getString(),
  category: category.value,
  tags: tags.map((tag) => tag.value),
  author: typeof author !== 'undefined' ? author.value : undefined,
  reviewers: reviewers.map((reviewer) => reviewer.value),
  isPreview
});

const createArticle = (formData) => (dispatch) => {
  const dto = convertSetArticleToDB(formData);
  dispatch(actionArticlesFormSubmitProgress());

  api
    .post('/blog/article/create', dto)
    .then(({ status }) => {
      if (status !== CREATED) {
        throw new Error();
      }

      dispatch(actionArticlesFormSubmitSuccess('The new article has been successfully created'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionArticlesFormSubmitFailed(msg));
    });
};

const convertDBArticleToEdit = ({
  image,
  title,
  seo,
  header,
  content,
  read,
  anchors,
  slug,
  category,
  tags,
  author,
  reviewers,
  stats,
  created,
  updated,
  createdAt,
  updatedAt
}) => ({
  image: image || null,
  title,
  seoTitle: seo.title,
  seoDescription: seo.description,
  header,
  content,
  read,
  anchors,
  slug,
  category: { value: category.id, label: category.title },
  tags: tags.map((tag) => ({ value: tag.id, label: tag.title })),
  author: { value: author.id, label: new User(author.user).name },
  reviewers: reviewers.map((reviewer) => ({ value: reviewer.id, label: new User(reviewer.user).name })),
  stats,
  created,
  updated,
  createdAt,
  updatedAt
});

const getArticle = (articleId) => (dispatch) => {
  dispatch(actionGetArticleProgress());

  api
    .get(`/blog/article/${articleId}`)
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(actionGetArticleSuccess(convertDBArticleToEdit(data)));
    })
    .catch(() => dispatch(actionGetArticleFailed()));
};

const updateArticle = (articleId, formData) => (dispatch) => {
  const dto = convertSetArticleToDB(formData);
  dispatch(actionArticlesFormSubmitProgress());

  api
    .put(`/blog/article/${articleId}`, dto)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionArticlesFormSubmitSuccess('The article has been successfully edited'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionArticlesFormSubmitFailed(msg));
    });
};

const previewArticle = (formData, articleId, isBlogAuthor = false) =>
  new Promise((resolve, reject) => {
    const createPreview = (dto) =>
      new Promise((res, rej) => {
        api
          .post('/blog/article/create', dto)
          .then(({ status, data }) => {
            if (status !== CREATED) {
              throw new Error();
            }

            res(data.id);
          })
          .catch((error) => rej(error));
      });

    const updatePreview = (dto) =>
      new Promise((res, rej) => {
        api
          .put(`/blog/article/${articleId}`, dto)
          .then(({ status }) => {
            if (status !== NO_CONTENT) {
              throw new Error();
            }

            res(articleId);
          })
          .catch((error) => rej(error));
      });

    const updateAuthorAndPreview = (dto) =>
      new Promise((res, rej) => {
        api
          .put(`/blog/article/editAuthor/${articleId}`, { author: dto.author })
          .then((response) => {
            const { status } = response;

            if (status !== NO_CONTENT) {
              throw new Error();
            }

            const dtoWithoutAuthor = dto;
            delete dtoWithoutAuthor.author;

            return updatePreview(dtoWithoutAuthor);
          })
          .then((id) => res(id))
          .catch((error) => rej(error));
      });

    Promise.resolve(convertSetArticleToDB(formData))
      .then((dto) =>
        // eslint-disable-next-line no-nested-ternary
        !articleId ? createPreview(dto) : !isBlogAuthor ? updateAuthorAndPreview(dto) : updatePreview(dto)
      )
      .then((previewId) => resolve(previewId))
      .catch(({ response }) => {
        let msg = ERROR_ON_THE_SERVER;

        if (response) {
          const { message, error } = response.data;
          msg = new ErrorMessage(message, error).getMessage();
        }

        reject(msg);
      });
  });

const removeArticle = (articleId) => (dispatch) => {
  dispatch(actionArticlesFormSubmitProgress());

  api
    .delete(`/blog/article/${articleId}`)
    .then(({ status }) => {
      if (status !== NO_CONTENT) {
        throw new Error();
      }

      dispatch(actionArticlesFormSubmitSuccess('The article has been successfully removed'));
    })
    .catch(({ response }) => {
      let msg = ERROR_ON_THE_SERVER;

      if (response) {
        const { message, error } = response.data;
        msg = new ErrorMessage(message, error).getMessage();
      }

      dispatch(actionArticlesFormSubmitFailed(msg));
    });
};

const getAuxiliaryData = () => (dispatch) => {
  dispatch(actionGetArticleAuxiliaryDataProgress());

  api
    .get('/blog/article/auxiliaryData')
    .then(({ status, data }) => {
      if (status !== OK) {
        throw new Error();
      }

      dispatch(
        actionGetArticleAuxiliaryDataSuccess({
          categories: data.categories.map((category) => ({ value: category.id, label: category.value })),
          tags: data.tags.map((tag) => ({ value: tag.id, label: tag.value })),
          authors: data.authors.map((author) => ({ value: author.id, label: author.value }))
        })
      );
    })
    .catch(() => dispatch(actionGetArticleAuxiliaryDataFailed()));
};

const findArticleBySlug = (slug, id) =>
  new Promise((resolve) => {
    api
      .get('/blog/article/findBySlug', { params: { slug, id } })
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve(false);
      })
      .catch(() => resolve(true));
  });

const updateArticleAuthor = (articleId, authorId) =>
  new Promise((resolve, reject) => {
    api
      .put(`/blog/article/editAuthor/${articleId}`, { author: authorId })
      .then(({ status }) => {
        if (status !== NO_CONTENT) {
          throw new Error();
        }

        resolve('The author of the article has been successfully edited');
      })
      .catch(({ response }) => {
        let msg = ERROR_ON_THE_SERVER;

        if (response) {
          const { message, error } = response.data;
          msg = new ErrorMessage(message, error).getMessage();
        }

        reject(msg);
      });
  });

export {
  GET_ARTICLES_LIST_PROGRESS,
  GET_ARTICLES_LIST_SUCCESS,
  GET_ARTICLES_LIST_FAILED,
  ARTICLES_FORM_SUBMIT_PROGRESS,
  ARTICLES_FORM_SUBMIT_SUCCESS,
  ARTICLES_FORM_SUBMIT_FAILED,
  GET_ARTICLE_PROGRESS,
  GET_ARTICLE_SUCCESS,
  GET_ARTICLE_FAILED,
  GET_ARTICLE_AUXILIARY_DATA_PROGRESS,
  GET_ARTICLE_AUXILIARY_DATA_SUCCESS,
  GET_ARTICLE_AUXILIARY_DATA_FAILED,
  ARTICLES_CLEAR_STATE,
  actionArticlesClearState,
  getArticlesList,
  createArticle,
  getArticle,
  updateArticle,
  previewArticle,
  removeArticle,
  getAuxiliaryData,
  findArticleBySlug,
  updateArticleAuthor
};
