let apiUrl;
let frontUrl;
const { protocol, hostname } = window.location;

if (hostname === 'localhost') {
  apiUrl = `${protocol}//${hostname}:3005/api/admin`;
  frontUrl = 'http://localhost:3330';
} else if (hostname === 'admin.parentry.com') {
  apiUrl = 'https://api.parentry.com/api/admin';
  frontUrl = 'https://parentry.com';
} else if (hostname === 'admin.parentry.xyz') {
  apiUrl = 'https://api.parentry.xyz/api/admin';
  frontUrl = 'https://parentry.com';
} else {
  apiUrl = 'https://api.parentry.xyz/api/admin';
  frontUrl = 'https://parentry.com';
}

const config = {
  urls: {
    api: apiUrl,
    front: frontUrl,
    cdn: 'https://cdn.parentry.com/'
  }
};

export default config;
